import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import DataPreviewModal from "./DataPreviewModal";
import { toast } from "react-toastify";

const IOColumnsDrawer = ({ isOpen, setIsOpen, onClose, columns, fileUrl }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [inputColumns, setInputColumns] = useState([]);
  const [outputColumns, setOutputColumns] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State to manage search term
  const [isDataPrevModal, setIsDataPrevModal] = useState(false);
  const [currentModal, setCurrentModal] = useState("main");

  const handleSelectAll = (isInput) => {
    const filteredCols = filteredColumns.filter((col) =>
      isInput ? !outputColumns.includes(col) : !inputColumns.includes(col)
    );
    if (isInput) {
      if (inputColumns.length === filteredCols.length) {
        setInputColumns([]);
      } else {
        setInputColumns(filteredCols);
      }
    } else {
      if (outputColumns.length === filteredCols.length) {
        setOutputColumns([]); // Deselect all
      } else {
        setOutputColumns(filteredCols);
      }
    }
  };

  const handleDataPreviewModal = () => {
    if (outputColumns.length === 0) {
      toast.error("Please select at least one output column.");
      return;
    }
    setCurrentModal("Data Preview");
    setIsDataPrevModal(true);
  };

  const goBackToMainModal = () => {
    setCurrentModal("main");
    setIsDataPrevModal(false);
  };

  // Function to handle individual column selection
  const handleSelectColumn = (column, isInput) => {
    if (isInput) {
      const updatedInputColumns = inputColumns.includes(column)
        ? inputColumns.filter((col) => col !== column)
        : [...inputColumns, column];
      setInputColumns(updatedInputColumns);
    } else {
      const updatedOutputColumns = outputColumns.includes(column)
        ? outputColumns.filter((col) => col !== column)
        : [...outputColumns, column];
      setOutputColumns(updatedOutputColumns);
    }
  };

  const filteredColumns = columns.filter((col) =>
    col.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {/* Only render IOColumnsDrawer if the DataPreviewModal is not open */}
      {!isDataPrevModal && isOpen && currentModal === "main" && (
        <div
          className={`absolute top-0 right-0 h-full w-1/3 bg-white shadow-lg rounded-l-md p-4 transition-transform ${
            isOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          {/* Tab Titles */}
          <div className="min-h-[58px] px-2 w-1/2 bg-white shadow-md flex gap-x-6 items-end justify-start">
            <span
              onClick={() => setSelectedTab(0)}
              className={`min-w-fit text-[14px] font-[400] cursor-pointer pb-3 px-1 border-b-[3px] border-white ${
                selectedTab === 0 ? "text-gen-color border-b-gen-color" : ""
              }`}
            >
              Input Columns ({inputColumns.length}){" "}
              {/* Show input columns count */}
            </span>
            <span
              onClick={() => setSelectedTab(1)}
              className={`min-w-fit text-[14px] font-[400] cursor-pointer pb-3 px-1 border-b-[3px] border-white ${
                selectedTab === 1 ? "text-gen-color border-b-gen-color" : ""
              }`}
            >
              Output Columns ({outputColumns.length}){" "}
              {/* Show output columns count */}
            </span>
          </div>

          <hr />
          <div className="flex flex-col mt-2">
            <h2 className="text-base font-semibold">Columns</h2>
          </div>
          {/* Search Bar */}
          <div className="flex items-center mt-4 mb-4 bg-gray-100 p-2 rounded-lg">
            <AiOutlineSearch className="mr-2 text-gray-600 text-[24px]" />{" "}
            {/* Increase icon size */}
            <input
              type="text"
              placeholder="Search columns"
              className="w-full bg-transparent focus:outline-none text-gray-700"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {/* Select All Option */}
          <div className="ml-4 items-center">
            <input
              type="checkbox"
              className="mr-2"
              checked={
                selectedTab === 0
                  ? inputColumns.length ===
                    filteredColumns.filter(
                      (col) => !outputColumns.includes(col)
                    ).length
                  : outputColumns.length ===
                    filteredColumns.filter((col) => !inputColumns.includes(col))
                      .length
              }
              onChange={() => handleSelectAll(selectedTab === 0)}
            />
            <span className="text-sm -mb-4">Select All</span>
          </div>

          {/* Scrollable Columns List */}
          <div className="overflow-y-scroll max-h-[52%] mt-4 mb-2">
            {" "}
            {/* Adjusted for scrolling */}
            {selectedTab === 0
              ? filteredColumns
                  .filter((col) => !outputColumns.includes(col))
                  .map((column) => (
                    <div
                      key={column}
                      className={`flex items-center py-2 px-4 text-sm cursor-pointer hover:bg-gray-200 ${
                        inputColumns.includes(column) ? "bg-gray-300" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        id={`input-${column}`}
                        checked={inputColumns.includes(column)}
                        onChange={() => handleSelectColumn(column, true)}
                        className="mr-2"
                      />
                      <label
                        htmlFor={`input-${column}`}
                        className="cursor-pointer"
                      >
                        {column}
                      </label>
                    </div>
                  ))
              : filteredColumns
                  .filter((col) => !inputColumns.includes(col))
                  .map((column) => (
                    <div
                      key={column}
                      className={`flex items-center py-2 px-4 text-sm cursor-pointer hover:bg-gray-200 ${
                        outputColumns.includes(column) ? "bg-gray-300" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        id={`output-${column}`} // Unique ID for output checkbox
                        checked={outputColumns.includes(column)}
                        onChange={() => handleSelectColumn(column, false)}
                        className="mr-2"
                      />
                      <label
                        htmlFor={`output-${column}`}
                        className="cursor-pointer"
                      >
                        {column}
                      </label>
                    </div>
                  ))}
          </div>

          {/* Done Button */}
          <div className="absolute bottom-0 left-0 w-full p-4 bg-gray-100 border-t border-gray-300">
            <button
              className="w-full bg-white text-purple-600 font-semibold py-2 rounded-md"
              onClick={handleDataPreviewModal}
            >
              Done
            </button>
          </div>
        </div>
      )}
      {/* Data Preview Modal */}
      {isDataPrevModal && currentModal === "Data Preview" && (
        <DataPreviewModal
          goBack={goBackToMainModal}
          isOpen={isDataPrevModal}
          setIsOpen={setIsOpen}
          onClose={onClose}
          inputColumns={inputColumns}
          outputColumns={outputColumns}
          fileUrl={fileUrl}
          // onDataSubmit={handleOutputData}
          className="z-[1000]"
        />
      )}
    </>
  );
};

export default IOColumnsDrawer;
