
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs';
import axiosInstance from '../../api/axios';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function BillingTab() {
    const [creditFromDate, setCreditFromDate] = useState(dayjs('2022-04-17'));
    const [creditToDate, setCreditToDate] = useState(dayjs());
    const [creditHistory, setCreditHistory] = useState([]);
    const [filteredCreditHistory, setFilteredCreditHistory] = useState([]);

    const [openTab, setOpenTab] = useState(1);
    const [transaction, setTransaction] = useState([]);
    const [fromDate, setFromDate] = useState(dayjs('2022-04-17')); // Default from date
    const [toDate, setToDate] = useState(dayjs()); // Default to date as todays
    const [filteredTransactions, setFilteredTransactions] = useState([]); // State to hold filtered transactions

    const apiURL = process.env.REACT_APP_BASE_URL;
    const PaymentapiURL = process.env.REACT_APP_PAYMENT_SERVICE_URL;

    // Call filterTransactions function whenever fromDate or toDate changes
    useEffect(() => {
        const filterTransactions = () => {
            const randomDate = dayjs('2023-02-09'); 
            const filtered = Array.isArray(transaction) ? transaction.filter((ele) => {
                const paymentDate = ele.payment_date_time ? dayjs(ele.payment_date_time) : randomDate;
                return paymentDate.isBetween(fromDate, toDate, null, '[]'); // Check if payment date is within the selected range
            }) : [];
            setFilteredTransactions(filtered);
        };
        filterTransactions();
    }, [fromDate, toDate, transaction]);

    useEffect(() => {
        const filterCreditHistory = () => {
            const filtered = creditHistory.filter((ele) => {
                const paymentDate = dayjs(ele.created_date);
                return paymentDate.isBetween(creditFromDate, creditToDate, null, '[]');
            });
            setFilteredCreditHistory(filtered);
        };
        filterCreditHistory();
    }, [creditFromDate, creditToDate, creditHistory]);


    useEffect(() => {
        const loadcreditHistory = () => {
            // Define the URL with query parameters
            // const token = localStorage.getItem('token');
            const url = `${PaymentapiURL}/creditHistory/`;
            // const headers = {
            //     Authorization: `Bearer ${token}`,
            //     'Content-Type': 'application/json',
            // };

            // Make the GET request using Axios
            axiosInstance.get(url, {
                // params: params,
                // headers: headers,
            })
                .then(response => {
                    // Handle the successful response
                    const data = response.data.data;


                    // Process the data here and set it to the listCard state

                    // Update the state with the processed data
                    setCreditHistory(data);
                })
                .catch(error => {
                    // Handle any errors that occur during the request
                    console.error('Error:', error);
                });
        }
        loadcreditHistory();
    }, [PaymentapiURL, apiURL]);

    useEffect(() => {
        const loadTransaction = () => {
            // Define the URL with query parameters
            // const token = localStorage.getItem('token');
            const url = `${PaymentapiURL}/paymentHistory/`;
            // const headers = {
            //     Authorization: `Bearer ${token}`,
            //     'Content-Type': 'application/json',
            // };

            // Make the GET request using Axios
            axiosInstance.get(url, {
                // params: params,
                // headers: headers,
            })
                .then(response => {
                    // Handle the successful response
                    const data = response.data.data;

                    // Process the data here and set it to the listCard state

                    // Update the state with the processed data
                    setTransaction(data);
                })
                .catch(error => {
                    // Handle any errors that occur during the request
                    console.error('Error:', error);
                });
        }
        loadTransaction();
    }, [PaymentapiURL, apiURL]);
    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full">
                    <ul
                        className="flex list-none px-6 border-b space-x-8"
                        role="tablist"
                    >
                        <li className={`${openTab === 1 ? "text-tab-active-color border-b-4 border-tab-active-color" : "text-gray-60"} text-base font-bold px-1.5 pb-3`}>
                            <a
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(1);
                                }}

                                data-toggle="tab"
                                href="#link1"
                                role="tablist"
                            >
                                Payment
                            </a>
                        </li>

                        <li className={`${openTab === 2 ? "text-tab-active-color border-b-4 border-tab-active-color" : "text-gray-60"} text-base font-bold px-1.5 pb-3`}>
                            <a
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(2);
                                }}
                                data-toggle="tab"
                                href="#link2"
                                role="tablist"
                            >
                                Usage
                            </a>
                        </li>
                    </ul>

                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
                        <div className="px-4 py-5 flex-auto">
                            <div className="tab-content tab-space">
                                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                                    <div className='flex justify-end mb-6'>
                                        <span className="mr-2 mt-6 font-normal text-sm">Filter By : </span>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                <DatePicker label="From" value={fromDate} onChange={(newValue) => setFromDate(newValue)} />
                                                <DatePicker
                                                    label="To"
                                                    value={toDate}
                                                    onChange={(newValue) => setToDate(newValue)}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                    <div className="container mx-auto h-56 overflow-y-auto">
                                        <table className="min-w-full">
                                            <thead className='bg-tab-not-active-color'>
                                                <tr>
                                                    <th className="py-2 px-4 text-left">Date</th>
                                                    <th className="py-2 px-4 text-left">Amount</th>
                                                    <th className="py-2 px-4 text-left">Status</th>
                                                    {/* <th className="py-2 px-4 text-left">Invoice</th> */}
                                                    <th className="py-2 px-4 text-left"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    filteredTransactions.length !== 0 ?
                                                        filteredTransactions.map((ele, i) =>
                                                            <tr key={i}>
                                                                <td className="py-2 px-4">{ele.payment_date_time}</td>
                                                                <td className="py-2 px-4">$ {ele.amount}</td>
                                                                <td className="py-2 px-4">{ele.payment_status}</td>
                                                                {/* <td className="py-2 px-4">Invoice #010 - Nov 2023</td>
                                                                <td className="py-2 px-4 cursor-pointer">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                        <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#2E1F15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                        <path d="M5.83301 8.33398L9.99967 12.5007L14.1663 8.33398" stroke="#2E1F15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                        <path d="M10 12.5V2.5" stroke="#2E1F15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    </svg>
                                                                </td> */}
                                                            </tr>
                                                        ) : <tr><td className='text-center py-2' colSpan="5">No Transaction yet made</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className={`${openTab === 2 ? "block" : "hidden"}  overflow-y-auto`} id="link2">
                                    <div className='flex justify-end mb-6'>
                                        <span className="mr-2 mt-6 font-normal text-sm">Filter By : </span>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                <DatePicker label="From" value={creditFromDate} onChange={(newValue) => setCreditFromDate(newValue)} />
                                                <DatePicker
                                                    label="To"
                                                    value={creditToDate}
                                                    onChange={(newValue) => setCreditToDate(newValue)}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                    <div className="container mx-auto h-56 overflow-y-auto">
                                        <table className="min-w-full">
                                            <thead className='bg-tab-not-active-color'>
                                                <tr>
                                                    <th className="py-2 px-4 text-left">Date</th>
                                                    <th className="py-2 px-4 text-left">Plan Name</th>
                                                    <th className="py-2 px-4 text-left">Credit</th>
                                                    {/* <th className="py-2 px-4 text-left">Credit Spent</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    filteredCreditHistory.length !== 0 ?
                                                        filteredCreditHistory.map((ele, i) =>
                                                            <tr key={i}>
                                                                <td className="py-2 px-4">{ele.start_time}</td>
                                                                <td className="py-2 px-4">{ele.name}</td>
                                                                <td className="py-2 px-4">$ {ele.amount}</td>
                                                                {/* <td className="py-2 px-4">Invoice #010 - Nov 2023</td> */}
                                                                {/* <td className="py-2 px-4 cursor-pointer">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#2E1F15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M5.83301 8.33398L9.99967 12.5007L14.1663 8.33398" stroke="#2E1F15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M10 12.5V2.5" stroke="#2E1F15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                            </td> */}
                                                            </tr>
                                                        ) : <tr><td className='text-center py-2' colSpan="5">No Credit History yet made</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BillingTab
