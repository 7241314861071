import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import login_or_img from "./../../assets/ui_img/login-or-line-img.svg";
import Slider from "./Slider";
import AuthBtn from "../AuthBtn";
import { MdClose } from "react-icons/md";
import qubrid_ai_logo from "../../assets/white-ai-logo.svg";
import axiosInstance from "../../api/axios";
function SignUp() {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const onSubmit = async (data) => {
    try {
      // Remove confirmPassword from the data
      const UserapiURL = process.env.REACT_APP_USER_SERVICE_URL;
      const { confirmPassword, ...restData } = data;
      const { data: res } = await axiosInstance.post(`${UserapiURL}/register`, restData);
      console.log(res);
     
      if (res.success) {
        // toast("User Registered Successfully");
        toast(res.message);
        navigate("/login");
      } else {
        toast("Something went wrong");
        toast(res.message || "No error message provided");
      }
    } catch (error) {
      console.error(error);
     
      // Check if the error has a response (from the server) or just a general message
      let errorMessage = error.response?.data?.message[0] || error.message[0] || "Something went wrong";
      if(errorMessage === "E") {
        errorMessage = error.response?.data?.message || error.message
      }
      toast(errorMessage);
    }
  };
  
  

  return (
    <>
      <div className="flex items-center justify-center min-h-screen">
        <div className="h-[700px] bg-white shadow-lg rounded-lg">
          <div className="flex justify-between w-full h-full">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="h-full flex flex-col justify-between">
                <div className={`${errors ? "mt-14" : "mt-8"} text-center`}>
                  <h5 className="text-3xl font-bold text-card-li-color">
                    Create your account
                  </h5>
                  <p
                    className={`text-base font-normal text-card-li-color ${
                      errors ? "py-1" : "py-3"
                    }`}
                  >
                    Please enter your details to sign up
                  </p>
                </div>

                <div className="px-20">
                  <AuthBtn />
                  <div className="py-2">
                    <img
                      src={login_or_img}
                      className="m-auto"
                      alt="login_or_img"
                    />
                  </div>

                  <div style={{ maxWidth: "511px" }}>
                    <div className="flex gap-5">
                      <div className="w-full">
                        <Controller
                          name="first_name"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "First Name is required",
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="outlined-basic"
                              label="First Name"
                              variant="outlined"
                              sx={{ width: "100%" }}
                            />
                          )}
                        />
                        {errors.first_name && <p className='text-sm font-semibold text-red-600'>{errors.first_name.message}</p>}
                      </div>

                      <div className="w-full">
                        <Controller
                          name="last_name"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Last Name is required",
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="outlined-basic"
                              label="Last Name"
                              variant="outlined"
                              sx={{ width: "100%" }}
                            />
                          )}
                        />
                        {errors.last_name && <p className='text-sm font-semibold text-red-600'>{errors.last_name.message}</p>}
                      </div>
                    </div>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Email is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Invalid email address",
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="outlined-basic"
                          label="Email"
                          variant="outlined"
                          sx={{ width: "100%", marginTop: 1.5 }}
                        />
                      )}
                    />
                    {errors.email && (
                      <p className="text-sm font-semibold text-red-600">
                        {errors.email.message}
                      </p>
                    )}
                    <div className="flex gap-5">
                      <div className="w-full">
                        <Controller
                          name="mobile_number"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Mobile Number is required",
                            pattern: {
                              value: /^[0-9]{10}$/,
                              message: "Invalid mobile number",
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="outlined-basic"
                              label="Mobile Number"
                              variant="outlined"
                              sx={{ width: "100%", marginTop: 1.5 }}
                            />
                          )}
                        />
                        {errors.mobile_number && <p className='text-sm font-semibold text-red-600'>{errors.mobile_number.message}</p>}
                      </div>
                      <div className="w-full">
                        <Controller
                          name="organization_name"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="outlined-basic"
                              label="Organization Name"
                              variant="outlined"
                              sx={{ width: "100%", marginTop: 1.5 }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="flex gap-5">
                      <div className="w-full">
                        <Controller
                          name="password"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Password is required",
                            minLength: {
                              value: 6,
                              message: "Password must be at least 6 characters",
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="outlined-password-input"
                              label="Password"
                              type={showPassword ? "text" : "password"}
                              autoComplete="current-password"
                              sx={{ width: "100%", marginTop: 1.5 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                        {errors.password && (
                          <p className="text-sm font-semibold text-red-600">
                            {errors.password.message}
                          </p>
                        )}
                      </div>
                      <div className="w-full">
                        <Controller
                          name="confirmPassword"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Confirm Password is required",
                            validate: (value) =>
                              value === watch("password") ||
                              "Passwords do not match",
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="outlined-password-input"
                              label="Confirm Password"
                              type={showConfirmPassword ? "text" : "password"}
                              autoComplete="current-password"
                              sx={{ width: "100%", marginTop: 1.5 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        setShowConfirmPassword(
                                          !showConfirmPassword
                                        )
                                      }
                                      edge="end"
                                    >
                                      {showConfirmPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                        {errors.confirmPassword && (
                          <p className="text-sm font-semibold text-red-600">
                            {errors.confirmPassword.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <FormGroup className="-mt-1">
                      <Controller
                        name="is_accept_terms_condition"
                        control={control}
                        defaultValue={false}
                        rules={{
                          validate: (value) =>
                            value === true ||
                            "You must agree to the terms and conditions",
                        }}
                        render={({ field }) => (
                          <FormControlLabel
                            control={<Checkbox {...field} />}
                            label={
                              <span
                                onClick={() => setAgreement(true)}
                                className="text-card-hover-text-color underline"
                              >
                                Terms & Conditions
                              </span>
                            }
                          />
                        )}
                      />
                      {errors.is_accept_terms_condition && <p className='text-sm font-semibold text-red-600 -mt-2'>{errors.is_accept_terms_condition.message}</p>}
                    </FormGroup>
                  </div>
                </div>

                <div className={`${errors ? "pb-10" : "pb-8"} bottom-0 px-20`}>
                  <button className="general-button w-full">Sign Up</button>
                  <div className="flex justify-center gap-2.5">
                    <p className="py-2 text-base font-medium text-card-li-color">
                      Already signed up?
                    </p>
                    <Link to="/login" className="cursor-pointer">
                      <p className="py-2 text-base font-medium text-card-hover-text-color underline">
                        Sign In
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </form>

            {/* <div className="relative w-1/2 bg-img-login h-auto">
              <div className="w-full h-2/3 flex flex-col justify-center items-center">
                <img src={qubrid_ai_logo} alt="qubrid_login_img" />
              </div>

              <div className="absolute bottom-10 w-full">
                <Slider />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {agreement ? (
        <>
          <div className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none w-2/3 m-auto">
            <div className="relative my-6 mx-auto">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-6 border-b border-solid border-slate-200 rounded-t">
                  <div>
                    <h5 className="text-xl font-semibold">Confirmation</h5>
                  </div>

                  <button
                    className=" background-transparent font-bold uppercase pt-1.5 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setAgreement(false)}
                  >
                    <MdClose className="w-5 h-5" />
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto h-70vh overflow-y-auto">
                  <div className="text-xl font-semibold text-black text-center mb-4">
                    <span>Qubrid End User Agreement</span>
                  </div>
                  <p>
                    This Qubrid End User Agreement is between the End User
                    (“Customer”), and Qubrid. By accepting this Agreement,
                    Customer accepts the terms of this Agreement:
                  </p>
                  <div className="my-2">
                    <div className="text-xl font-semibold text-black">
                      <span>Services Offered</span>
                    </div>
                    <p>
                      Qubrid will offer cloud services to the Customer. Such
                      Cloud Services will consist of but not limited to Cloud
                      based CPU and GPU instances, access to Quantum Computing
                      systems and the hardware, software, and applications
                      required to use them, programming tools, Quantum Computing
                      software development kits SDK, application programming
                      interfaces (“API”), Quantum Simulators and other related
                      services.
                    </p>
                  </div>
                  <div>
                    <div className="text-xl font-semibold text-black">
                      <span>Order Acceptance and Payment Terms</span>
                    </div>
                    <p>
                      When you place an order with Qubrid, it does not
                      constitute an automatic commitment or confirmation of our
                      offer to sell. Qubrid reserves the right at any time after
                      receipt of your order to accept or decline your order for
                      any reason. All orders will be fulfilled only after
                      confirmation and agreement on acceptable method of payment
                      by our credit and finance department. Qubrid can cancel
                      any order at any time due to product short supply,
                      recognition of fraudulent transactions or for other
                      reasons deemed suitable by the company. In situations
                      where Qubrid cancels the order prior to fulfillment,
                      except for suspected fraudulent transactions, Qubrid will
                      promptly refund the order amount through the payment
                      method that was used during ordering. Qubrid offers
                      multiple methods of payments such as credit card, wire
                      transfer, net terms etc depending on type of entity,
                      credit profile etc. Customer shall bear all applicable
                      federal, state, municipal and other government taxes (such
                      as sales, use, and similar taxes). We accept major credit
                      cards, including Visa, MasterCard, Discover and American
                      Express. Government, Education and Company Purchase Orders
                      are accepted, subject to account verification.{" "}
                    </p>
                  </div>
                  <div className="my-2">
                    <div className="text-xl font-semibold text-black">
                      <span>License</span>
                    </div>
                    <p>
                      For the term of this Agreement, Qubrid grants the Customer
                      a limited, nonexclusive, nontransferable license to access
                      and use Qubrid Cloud Platform services.
                    </p>
                  </div>
                  <div>
                    <div className="text-xl font-semibold text-black">
                      <span>Cloud Credits</span>
                    </div>
                    <p>
                      All cloud credits offered by Qubrid or purchased by
                      Customers will have a maximum validity of 12 months unless
                      there is a separate agreement with the Customer for longer
                      term reserved cloud instances. Credits have no refund
                      value and are only to be used to deploy cloud or other
                      Qubrid instances and applications and are revocable for
                      abuse, misrepresentation of account information,
                      unauthorized transfer, or illegal conduct.{" "}
                    </p>
                  </div>
                  <div className="my-2">
                    <div className="text-xl font-semibold text-black">
                      <span>Intellectual Property</span>
                    </div>
                    <p className="my-2">
                      Customer retains all rights to the Intellectual Property
                      as developed by the Customer on Qubrid platform including
                      algorithms and applications and as related to patents,
                      copyright, trademark or other intellectual property
                      rights. All intellectual property rights and title to
                      pre-existing materials are unaffected by this Agreement.{" "}
                    </p>
                    <p>
                      Qubrid retains all right, title, and interest in and to
                      the Qubrid Intellectial Property, including cloud
                      architecture, pricing structure, software code, algorithms
                      developed by Qubrid etc and Customer will have no title or
                      ownership to Qubrid Intellectual Property.
                    </p>
                  </div>
                  <div>
                    <div className="text-xl font-semibold text-black">
                      <span>Data Protection and Platform Use</span>
                    </div>
                    <p className="my-2">
                      Customer acknowledges and agrees that Qubrid will be
                      hosting cloud services in United States or other
                      geographies using it’s own or partner datacenters
                      including that of public cloud providers. Customer agrees
                      to the hosting of their applications, algorithms and data
                      in the United States. Qubrid may transfer such data to
                      other geographic regions or jurisdictions at its sole
                      Customer represents and warrants that such hosting and
                      transfer are adequately consented to by Customer and
                      permissible pursuant to applicable laws, regulations,
                      rules, and third party rights. Qubrid will implement
                      reasonable and appropriate measures to secure the Customer
                      data against accidental or unauthorized access,
                      transmission, loss or disclosure. Qubrid will not take any
                      responsibility of loss of Customer data due to bad actors
                      or Act of God. Unless otherwise expressly specified by an
                      applicable Order, Qubrid shall have no obligation to
                      maintain Customer’s property, backup on behalf of Customer
                      or any user.
                    </p>
                    <p>
                      Customer shall ensure that the Cloud Services are properly
                      configured for its purposes and shall take reasonable
                      steps for securing and protecting Customer data. Customer
                      shall be responsible and liable for the activities of any
                      individual or entity who gains access to Customer Data or
                      the Qubrid Services as a result of its failure to comply
                      with the obligations of this clause. Customer shall be
                      responsible for ensuring that all third-party tools,
                      content and otherwise components added by Customer to the
                      Cloud Services shall conform with the terms of the
                      Agreement.{" "}
                    </p>
                  </div>

                  <div className="my-2">
                    <div className="text-xl font-semibold text-black">
                      <span>Termination</span>
                    </div>
                    <p className="my-2">
                      Either Party may terminate this Agreement and/or any Order
                      by providing written notice to the other Party in the
                      event the other Party materially breaches any of its
                      duties, obligations or responsibilities under this
                      Agreement and fails to cure such breach within thirty days
                      after receipt by the breaching Party of written notice
                      specifying the breach.
                    </p>
                  </div>

                  <div className="my-2">
                    <div className="text-xl font-semibold text-black">
                      <span>Privacy Policy</span>
                    </div>
                    <p className="my-2">
                      At Qubrid we collect Buyer’s Name, Contact Information,
                      Billing Address, Shipping Address, Order Details from our
                      Customers. Any information shared with our suppliers and
                      technology providers is for the sole intention of order
                      fulfillment. If you choose to create an online account,
                      your user name and password remain confidential and please
                      DO NOT share this information with anyone. If you elect to
                      receive our newsletter or special promotions, your contact
                      information will be used for the delivery of these items.
                      For some social proof/credibility applications for
                      marketing purposes, we may use logos, first name and
                      locations of Customers without ever using full address and
                      confidential information. For advertising and fulfillment
                      purposes, we may allow third-party companies to collect
                      non-personally identifiable information when Customers
                      visit our site.
                    </p>
                  </div>

                  <div>
                    <div className="text-xl font-semibold text-black">
                      <span>Limitation of Liability</span>
                    </div>
                    <p className="my-2">
                      In no event will Qubrid be liable for direct, special,
                      incidental, or consequential damages however caused and
                      regardless of the theory of liability including, but not
                      limited to, lost profits, fraud of any type, downtime,
                      goodwill, damage to or replacement of equipment and
                      property, and any costs of recovering, reprogramming, or
                      reproducing any document or data stored in or used with a
                      system containing these services.
                    </p>
                  </div>

                  <div>
                    <div className="text-xl font-semibold text-black">
                      <span>Applicable Law</span>
                    </div>
                    <p className="my-2">
                      All sales, transactions, and/or disputes between Qubrid
                      and the Customer shall be governed by and construed in
                      accordance with laws of the state of Virginia, U.S.A.
                    </p>
                  </div>
                </div>
                {/* Footer */}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b gap-x-3">
                  <button
                    type="button"
                    className={`bg-page-bg-color text-tab-active-color  text-base font-semibold px-12 py-3 rounded-full`}
                    onClick={() => setAgreement(false)}
                  >
                    No
                  </button>

                  <button
                    type="button"
                    className={`bg-gradient-pink-purple text-white text-base font-semibold  px-12 py-3 rounded-full`}
                    onClick={() => setAgreement(false)}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default SignUp;
