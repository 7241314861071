import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { MdClose } from "react-icons/md";
import { Visibility, VisibilityOff } from "@mui/icons-material"; // Import the visibility icons
import axiosInstance from "../../api/axios";
import { toast } from "react-toastify";

const ChangePassModal = ({ isOpen, setIsOpen }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // state for toggling password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // state for confirming password visibility
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false); // flag to track if the user touched confirm password

  if (!isOpen) return null;

  const handleSubmit = async () => {
    try {
      const response = await axiosInstance.post("/newuser/api/change-password", {
        password: password,
        confirm_password: confirmPassword,
      });
      toast("Password changed successfully");
      setIsOpen(false);
    } catch (error) {
      toast("Something went wrong");
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
        <div className="flex justify-between items-center border-b border-solid border-slate-200 rounded-t pb-4 mb-4">
          <h2 className="text-lg font-semibold">Change Password</h2>
          <button
            onClick={() => setIsOpen(false)}
            className="text-gray-500 hover:text-gray-700"
          >
            <MdClose className="w-5 h-5" />
          </button>
        </div>
        <div className="space-y-4">
          <TextField
            label="New Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={password.length > 0 && password.length < 6}
            helperText={password.length > 0 && password.length < 6 ? "Password must be at least 6 characters" : ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm New Password"
            type={showConfirmPassword ? "text" : "password"}
            fullWidth
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              if (!confirmPasswordTouched) {
                setConfirmPasswordTouched(true); // Mark the field as touched when the user starts typing
              }
            }}
            error={confirmPasswordTouched && confirmPassword !== password && confirmPassword.length > 0}
            helperText={confirmPasswordTouched && confirmPassword !== password && confirmPassword.length > 0 ? "Passwords do not match" : ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="flex justify-end mt-6 space-x-4">
          <button
            onClick={() => setIsOpen(false)}
            className="h-[42px] w-[78px] px-4 py-2 text-sm font-bold text-black bg-white border border-black rounded-lg hover:bg-gray-200 hover:border-none"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            type="button"
            className="classic-button h-[42px] w-[78px] text-[18px] font-[600]"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePassModal;
