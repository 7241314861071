import React, { useEffect, useState } from "react";
import axiosInstance from "../../api/axios";
import { GrDocumentCsv, GrDocumentPdf } from "react-icons/gr";

const SyntheticDataTab = ({
  openTab,
  onTabChange,
  convertToLocalTime,
  handleRefreshToken,
}) => {
  const [synthHisRes, setSynthHisRes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  // Function to load synth history
  const loadSynthHistory = async () => {
    const url = `/newuser/api/synth-history`;
    try {
      const response = await axiosInstance.get(url);
      const data = response?.data?.data;
      setSynthHisRes(data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          loadSynthHistory();
        }
      } else {
        console.error("Error loading synth history:", error);
      }
    }
  };

  useEffect(() => {
    // Call all functions once when the component mounts
    // loadModels();
    // loadInstance();
    loadSynthHistory();

    // Set interval to call them every 30 seconds
    const interval = setInterval(() => {
      //   loadModels();
      // loadInstance();
      loadSynthHistory();
    }, 30000);

    // Cleanup interval when component unmounts
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures this runs once when mounted

  const handleDownload = (url) => {
    window.open(url, "_blank");
  };

  const totalPages = Math.ceil(synthHisRes.length / pageSize);
  const currentSynth = synthHisRes.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };
  return (
    <>
      <div className={`${openTab === 3 ? "block" : "hidden"}`} id="link3">
        <table className="w-full">
          <thead className="bg-table-header-bg-color">
            <tr>
              <th className="py-4 px-10 text-sm font-semibold text-left">
                Sample File
              </th>
              <th className="py-4 px-10 text-sm font-semibold text-left">
                Generated Date
              </th>
              <th className="py-4 px-10 text-sm font-semibold text-left">
                Generated File
              </th>
              <th className="py-4 px-10 text-sm font-semibold text-left">
                Generated File Size
              </th>
              <th className="py-4 px-10 text-sm font-semibold text-left">
                Report
              </th>
            </tr>
          </thead>
          <tbody>
            {currentSynth
              ?.sort(
                (a, b) =>
                  new Date(b.generate_date_time) -
                  new Date(a.generate_date_time)
              )
              .map((ele) => (
                <tr
                  key={ele._id}
                  className={`${
                    ele._id % 2 === 0 ? "bg-white" : "bg-light-gray-1"
                  } w-full`}
                >
                  <td className="py-4 px-10 text-sm font-normal">
                    {ele.filename}
                  </td>
                  <td className="py-4 px-10 text-sm font-normal">
                    {convertToLocalTime(ele.generate_date_time)}
                  </td>
                  <td
                    className="py-4 px-10 text-sm font-normal flex cursor-pointer"
                    onClick={() =>
                      handleDownload(ele.generated_output_file_link)
                    }
                  >
                    Generated File
                    <GrDocumentCsv className="mt-0.5 ml-0.5" />
                  </td>
                  <td className="py-4 px-10 text-sm font-normal">
                    {ele.generated_csv_file_size}
                  </td>
                  <td
                    className="py-4 px-10 text-sm font-normal flex cursor-pointer"
                    onClick={() => handleDownload(ele.report_file_link)}
                  >
                    Download Report <GrDocumentPdf className="mt-0.5 ml-0.5" />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {/* Pagination */}
        <div className=" bg-white flex justify-end items-center border-t border-gray-100 p-2 space-x-3">
          <div className="flex items-center space-x-2">
            <span className="text-sm font-medium">Rows Per Page:</span>
            <select
              className="border rounded px-2 py-1 text-sm bg-rgba(248, 249, 253, 0.5)"
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
            </select>
          </div>
          <div className="flex items-center space-x-4">
            <span className="text-sm">
              {currentSynth.length > 0
                ? `${(currentPage - 1) * pageSize + 1} - ${Math.min(
                    currentPage * pageSize,
                    synthHisRes.length
                  )} of ${synthHisRes.length}`
                : "0"}
            </span>
            <button
              className={`px-3 py-1 border rounded ${
                currentPage === 1 && "opacity-50 cursor-not-allowed"
              }`}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
            <span className="text-sm">{currentPage}</span>
            <button
              className={`px-3 py-1 border rounded ${
                currentPage === totalPages && "opacity-50 cursor-not-allowed"
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SyntheticDataTab;
