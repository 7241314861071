import React from 'react';
import axios from "axios";
import TextField from '@mui/material/TextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Slider from './Slider';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import AuthBtn from '../AuthBtn';
import login_or_img from './../../assets/ui_img/login-or-line-img.svg';
import qubrid_ai_logo from '../../assets/white-ai-logo.svg';
function ForgetPass() {
  const { control, handleSubmit, formState: { errors } } = useForm();
  // const navigate = useNavigate();
  const UserapiURL = process.env.REACT_APP_USER_SERVICE_URL;
  // const UserapiURL = `https://backend.dev.qubrid.com/newuser/api`;
  const onSubmit = async (data) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
      };

      const response = await axios.post(`${UserapiURL}/forgot-password`, data, { headers });

      toast(response?.data?.message);
      // localStorage.setItem("reset_token", response?.data?.reset_token);
      // navigate('/newpass');
    } catch (error) {
      console.error('Error:', error);
      toast(error.response.data.message);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className='h-[600px] bg-white shadow-lg rounded-lg'>
        <div className="flex justify-between w-full h-full">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='h-full flex flex-col justify-between'>
              <div>
                <div className='px-20 pt-10'>
                  <Link to='/login'>
                    <ArrowBackIcon />
                  </Link>
                </div>
                <div className="text-center mt-4">
                  <h5 className="text-3xl font-bold text-card-li-color">Forgot Password</h5>
                  <p className="text-base font-medium text-card-li-color py-4">
                    Enter your email for the verification process
                  </p>
                </div>

                <div className=" pt-4 px-20 space-y-2">
                  <AuthBtn />

                  <div className="py-5">
                    <img src={login_or_img} className='m-auto' alt='login_or_img' />
                  </div>

                  <div>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: 'Email is required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Invalid email address',
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="outlined-basic"
                          label="Email"
                          variant="outlined"
                          sx={{ width: '100%' }}
                        />
                      )}
                    />
                    {errors.email && <p className='text-sm font-semibold text-red-600'>{errors.email.message}</p>}
                  </div>
                </div>
              </div>
              <div className="bottom-0 px-20 mb-20">
                <button
                  className="w-full bg-gradient-pink-purple text-white text-base font-semibold py-5 rounded-full"
                >
                  Reset
                </button>
              </div>
            </div>
          </form>

          {/* <div className="relative w-1/2 bg-img-login">
            <div className="w-full h-2/3 flex flex-col justify-center items-center">
              <img src={qubrid_ai_logo} alt="qubrid_login_img" />
            </div>

            <div className="absolute bottom-10 w-full">
              <Slider />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default ForgetPass;
