import axios from 'axios';
import React, { useEffect, useState } from 'react'


function CostAnalysis() {
    const [getCreditHistoryRes, setGetCreditHistoryRes] = useState();
    const BussinessapiURL = process.env.REACT_APP_BUSINESS_SERVICE_URL
    useEffect(() => {
        const getCreditHistory = () => {
            const token = localStorage.getItem("token");
            const headers = {
                'x-access-token': token,
                'Content-Type': 'application/json',
            };
            axios.get(`${BussinessapiURL}/credit-history`, { headers })
                .then(response => {
                    console.log(response)
                    setGetCreditHistoryRes(response?.data)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
        getCreditHistory();
    }, [BussinessapiURL]);

    return (
        <>
            <div className='flex justify-between py-3'>
                <div className='text-base font-semibold text-secondry-clr pt-3'>
                    List 
                    {/* ({getCreditHistoryRes?.credit_history?.length}) */}
                </div>
            </div>

            <table className="min-w-full">
                <thead className='bg-table-bg-head-clr'>
                    <tr>
                        <th className="py-2 px-4 text-left text-sm font-semibold text-secondry-clr">Email</th>
                        <th className="py-2 px-4 text-left text-sm font-semibold text-secondry-clr">Instance Type</th>
                        <th className="py-2 px-4 text-left text-sm font-semibold text-secondry-clr">Duration</th>
                        <th className="py-2 px-4 text-left text-sm font-semibold text-secondry-clr">Date & Time</th>
                        <th className="py-2 px-4 text-left text-sm font-semibold text-secondry-clr">Cost</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        getCreditHistoryRes?.credit_history?.map((ele, i) =>
                            <tr key={i} className={i % 2 !== 0 && "bg-page-bg-color bg-opacity-50"}>
                                <td className="py-2 px-4  text-sm font-normal text-secondry-clr">{ele?.user_email}</td>
                                <td className="py-2 px-4  text-sm font-normal text-secondry-clr">{ele?.plan_name}</td>
                                <td className="py-2 px-4  text-sm font-normal text-secondry-clr">1 Hours</td>
                                <td className="py-2 px-4  text-sm font-normal text-secondry-clr">{ele?.credit_date}</td>
                                <td className="py-2 px-4  text-sm font-normal text-secondry-clr">${ele?.credit}</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </>
    )
}

export default CostAnalysis