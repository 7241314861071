import React from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import Footer from "../Footer/Footer";
import {
  Container,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";

const DeployModel = () => {
  const codeSnippet = `
  # pip install bits and bytes accelerate
  from diffusers import StableDiffusionXLPipeline, StableDiffusionXLImg2ImgPipeline
  import torch

  pipeline = StableDiffusionXLPipeline.from_pretrained("stable-diffusion-xl-base-1.0", torch_dtype=torch.float16, variant="fp16", use_safetensors=True).to("cuda")
  refiner = StableDiffusionXLImg2ImgPipeline.from_pretrained("stable-diffusion-xl-refiner-1.0", torch_dtype=torch.float16, use_safetensors=True, variant="fp16").to("cuda")
  input_text = "a photo of an astronaut riding a horse on mars"
  `;

  const steps = [
    "Step 1: Ensure comprehensive coverage of your system for fine-tuning.",
    "Step 2: Prepare the fine-tuning dataset.",
    "Step 3: Fine-tune a base-model.",
    "Step 4: Evaluate your fine-tuned model.",
    "Step 5: Create a production-ready fine-tuned model.",
  ];

  return (
    <div className="flex w-full min-h-screen">
      {/* Sidebar Section */}
      <Sidebar className="flex-shrink-0 h-full fixed" />
      <div className="flex flex-col w-full">
        <div className="ml-[90px]">
          {/* Navbar Section */}
          <Navbar className="flex-shrink-0 fixed w-full" />
        </div>
        {/* page content */}
        <div className="ml-[90px] px-2 pt-[84px] mb-24">
          {/* Model Information Section */}
          <Container sx={{ maxWidth: { xs: "100%", sm: "1252px" } }}>
            {/* Breadcrumbs */}
            <div className="flex justify-between py-4">
              <div className="flex items-center">
                <Link to="/myInstance" state={{ openTab: 2 }}>
                  <Typography
                    variant="h6"
                    component="h2"
                    className="text-xl font-bold text-heading-black hover:text-blue-500"
                  >
                    Dashboard
                  </Typography>
                </Link>
                <MdKeyboardArrowRight className="text-2xl" />
                <Link
                  to={`/deploy`}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h2"
                    className="text-xl font-bold text-heading-black hover:text-blue-500"
                  >
                    Deploy
                  </Typography>
                </Link>
              </div>
            </div>
            {/* Deploy Code Section */}

            {/* Code Block */}
            <Paper elevation={3} sx={{ p: 2, mb: 4, bgcolor: "#fff" }}>
              <Typography
                variant="h6"
                sx={{
                  mb: 2,
                  color: "#10001A",
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              >
                Code
              </Typography>
              <SyntaxHighlighter language="python" style={materialDark}>
                {codeSnippet}
              </SyntaxHighlighter>
              <Typography
                variant="h6"
                sx={{
                  mt: 3,
                  color: "#10001A",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                Fine-tuning and self-hosting an LLM for command generation:
              </Typography>
              <List>
                {steps.map((step, index) => (
                  <ListItem key={index} sx={{ py: 0 }}>
                    <ListItemText primary={`${index + 1}. ${step}`} />
                  </ListItem>
                ))}
              </List>
            </Paper>

            {/* Fine-Tuning Steps */}
            {/* <Paper elevation={3} sx={{ p: 2 }}>
                
              </Paper> */}
          </Container>
        </div>
        {/* Footer Section */}
        <div className={`ml-[90px]`}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default DeployModel;
