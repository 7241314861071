import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";

const ModelDescription = ({ modelData }) => {
  return (
    <>
      <div className="min-h-[538px] w-full bg-white shadow-md p-8 ">
        <p className="text-justify text-[16px] font-[400]">
          {modelData.description}
        </p>
        <div className="mt-3">
          {/* references */}
          <p className="text-[16px] font-[600] pb-1">References:</p>
          {/* author */}
          <span className="text-[16px] font-[500]">Author: </span>
          <span>{modelData.author}</span>
          <br />
          {/* model page */}
          <span className="text-[16px] font-[500]">Model Page: </span>
          <a
            className={`${modelData.modelPageUrl && "hover:text-blue-500"}`}
            target="blank"
            href={modelData.modelPageUrl}
          >
            {modelData.modelPage}
          </a>
          {/* model card */}
          {modelData.modelCardUrl && (
            <div className="flex items-center">
              <br />
              <span className="text-[16px] font-[500]">Model Card &nbsp;</span>
              <a
                className="text-blue-500 text-sm"
                target="blank"
                href={modelData.modelCardUrl}
              >
                <FaExternalLinkAlt />
              </a>
            </div>
          )}
          {/* Resources and Technical Documentation*/}
          {modelData.docs && (
            <p className="text-[16px] font-[500]">
              Resources and Technical Documentation:
            </p>
          )}
          {modelData.docs &&
            modelData.docs.map((item, key) => {
              return (
                <>
                  {item[1] ? (
                    <div className="flex items-center ">
                      <span className=" pl-2">{key + 1}.</span>
                      <a
                        href={item[1]}
                        target="blank"
                        key={key}
                        className="pl-1 text-md text-gray-800 hover:text-blue-500 cursor-pointer"
                      >
                        {item[0]}
                      </a>
                    </div>
                  ) : (
                    <div>
                      <span className=" pl-2">{key + 1}.</span>
                      <a
                        href={item.url}
                        target="blank"
                        key={key}
                        className="pl-2 text-md text-gray-800 hover:text-blue-500 cursor-pointer"
                      >
                        {item.title}
                      </a>
                    </div>
                  )}
                </>
              );
            })}
          {/* Terms Of Use */}
          <div className="flex items-center">
            <span className="text-[16px] font-[600]">Terms of Use &nbsp;</span>
            <a
              className="text-blue-500 text-sm"
              target="blank"
              href={modelData.termsUrl}
            >
              <FaExternalLinkAlt />
            </a>
          </div>
          {/* Disclaimer */}
          <p className="text-[16px] font-[400]">
            Third-Party Community Consideration{" "}
          </p>
          <p className="text-[16px] font-[400]">
            {" "}
            This model is not owned or developed by Qubrid AI. This model has
            been developed and built to a third-party's requirements for this
            application and use case.
          </p>
        </div>
      </div>
    </>
  );
};

export default ModelDescription;
