// In your ModelContext.js or similar file
import React, { createContext, useContext, useState } from 'react';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [inputColumns, setInputColumns] = useState([]);
  const [outputColumns, setOutputColumns] = useState([]);
  const [fileUrl, setfileUrl] = useState("");
  return (
    <DataContext.Provider value={{ inputColumns, outputColumns, setInputColumns, setOutputColumns, fileUrl, setfileUrl }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
