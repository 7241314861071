import React, { useContext } from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Footer from '../Footer/Footer'
import { Context } from '../../Context'
import Tab from './Tab'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { Container } from '@mui/material'

function Home() {
    const { open } = useContext(Context)
    return (
        <div className='flex w-full min-h-screen'>
            <Sidebar className='flex-shrink-0 h-full fixed' />
            <div className='flex flex-col w-full'>
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                    <Navbar />
                </div>
                {/* Page Content */}
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"} px-1 pt-24 mb-24`}>
                    <Container>
                        {/* Your main content goes here */}
                        <div className='flex justify-between py-4'>
                            <div>
                                <div className="flex items-center">
                                    <Link to={'/'}>
                                        <h2 className="text-2xl font-bold text-heading-black">Home</h2>
                                    </Link>
                                    <MdKeyboardArrowRight className='text-2xl' />
                                    <h2 className="text-2xl font-bold text-heading-black">My Organization</h2>
                                </div>
                            </div>
                        </div>
                        <Tab />
                    </Container>
                </div>
                {/* Footer */}
                <div className={`${open ? "ml-[228px]" : "ml-[90px]"}`}>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Home