import React, { useState } from "react";
import "./slider.css";
import DialogueCard from "./DialogueCard";

const StepsSlider = ({ label, value, min, max, handleChange, text }) => {
  const [show, setShow] = useState(false);
  let valueLength = value.toString().length;

  return (
    <>
      <div className="relative mb-5 mt-6 max-w-full">
        <div className="flex items-center gap-2">
          <label className="block text-gray-700 text-sm font-medium">
            {label}
          </label>
          <div className="relative flex items-center">
            <span
              onMouseEnter={() => {
                setShow(true);
              }}
              onMouseLeave={() => {
                setShow(false);
              }}
              className="border border-gray-200 text-center rounded-full w-5 h-5 flex justify-center items-center bg-[#E9EAED] text-xs font-bold font-serif cursor-pointer"
            >
              i
            </span>
            <div className="absolute left-7">
              <DialogueCard show={show} text={text} />
            </div>
          </div>
        </div>
        <div className="relative mt-2 max-w-full">
          <span
            className={`${
              valueLength > 3 ? "px-3" : "px-5"
            } podition-slider absolute top-7 right-1 bg-white h-8 w-16 flex items-center justify-center transform -translate-x-1/2 -translate-y-full border border-black rounded-lg text-sm font-medium`}
          >
            {value}
          </span>
          <input
            type="range"
            min={min}
            max={max}
            step="1"
            className="w-full py-1 max-w-full lg:max-w-xs"
            value={value}
            onChange={handleChange}
            style={{
              "--value": value,
              "--min": min,
              "--max": max,
            }}
          />
          <div className="flex items-center w-[16.82vw] lg:max-w-xs justify-between -mt-2">
            <span className="text-sm font-medium">{min}</span>
            <span className="text-sm font-medium">{max}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepsSlider;
