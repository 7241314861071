import { Container } from "@mui/material";
import React, { useEffect } from "react";
import AiCompute from "./AiCompute";
import AiDataService from "./AiDataService";
import AiModelStudio from "./AiModelStudio";
import NowAvailable from "./NowAvailable";
import AiQuantumEngine from "./AiQuantumEngine";
import IwantSection from "./IwantSection";
import IwantSection2 from "./IwantSection2";

function Index() {
  useEffect(() => {
    localStorage.removeItem("activeTab");
  }, []);
  return (
    <Container>
      <IwantSection />
      {/* <NowAvailable /> */}
      {/* <IwantSection2 /> */}
      <AiModelStudio />
      <AiCompute />
      {/* <div className="flex flex-col md:flex-row gap-5 mt-3 w-full">
        <div className="w-full md:w-1/2">
          <AiDataService />
        </div>
        <div className="w-full md:w-1/2">
          <AiQuantumEngine />
        </div>
      </div> */}
    </Container>
  );
}

export default Index;
