import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import loader from "../../assets/ui_img/loader.svg";
import axiosInstance from "../../api/axios";
import { toast } from "react-toastify";
import {
  InfoOutlined,
  PlayCircleOutlineOutlined,
  StopCircleOutlined,
} from "@mui/icons-material";
import { TbTrashOff, TbExternalLinkOff } from "react-icons/tb";
import { GoLinkExternal } from "react-icons/go";
import { FaRegTrashAlt } from "react-icons/fa";

const InstanceTab = ({
  openTab,
  onTabChange,
  convertToLocalTime,
  handleRefreshToken,
}) => {
  const [myinstance, setMyinstance] = useState([]);
  const [launchingInstance, setLaunchingInstance] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteIns, setDeleteIns] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  // Function to load instances
  const loadInstance = async () => {
    const url = `/manage/user_instances`;
    try {
      const response = await axiosInstance.get(url);
      const data = response.data;
      setMyinstance(data.instances);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          loadInstance();
        }
      } else {
        console.error("Error loading instances:", error);
      }
    }
  };

  useEffect(() => {
    // Call all functions once when the component mounts

    loadInstance();

    // Set interval to call them every 30 seconds
    const interval = setInterval(() => {
      loadInstance();
    }, 30000);

    // Cleanup interval when component unmounts
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures this runs once when mounted
  // Function to handle opening Jupyter Notebook
  const handleOpen = async (ele) => {
    const notebookUrl = ele.jupyter_token; // Assuming jupyter_token contains the full URL
    window.open(notebookUrl, "_blank");
  };
  // Function to delete an instance
  const deleteInstance = async (ele) => {
    setLoading(true);
    sessionStorage.clear();
    const requestData = {
      plan_id: ele.plan_id,
      instance_id: ele.instance_id,
    };

    try {
      const response = await axiosInstance.post(
        `/manage/delete-instance`,
        requestData
      );
      toast(response?.data?.message);
      const cpuid = response.data.instance;
      try {
        await axiosInstance.post(`/manage/InActivatePlan`, { plan: cpuid });
        loadInstance();
      } catch (secondError) {
        console.error("Second API call error:", secondError);
      }
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          deleteInstance(ele);
        }
      } else {
        console.error("Error deleting instance:", error);
        toast(error.response?.data?.message || "Something went wrong");
        setLoading(false);
      }
    }
    setTimeout(() => {
      loadInstance();
    }, 1500);
  };

  // Function to start an instance
  const handleStart = async (ele) => {
    setLaunchingInstance(true);
    try {
      const response = await axiosInstance.post(`/manage/relaunch`, {
        instance_id: ele.instance_id,
      });
      loadInstance();
      toast(response.data.message);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          handleStart(ele);
        }
      } else {
        console.error("Error starting instance:", error);
        toast(error.response?.data?.message || "Failed to start instance");
      }
    } finally {
      setLaunchingInstance(false);
    }
  };

  // Function to stop an instance
  const handleStop = async (ele) => {
    setLaunchingInstance(true);
    try {
      const response = await axiosInstance.post(`/manage/stop-instance`, {
        instance_id: ele.instance_id,
      });
      loadInstance();
      toast(response.data.message);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          handleStop(ele);
        }
      } else {
        console.error("Error stopping instance:", error);
        toast(error.response?.data?.message || "Failed to stop instance");
      }
    } finally {
      setLaunchingInstance(false);
    }
  };

  const totalPages = Math.ceil(myinstance.length / pageSize);
  const currentInstances = myinstance.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  return (
    <>
      <div className={openTab === 1 ? "block" : "hidden"} id="link1">
        <table className="w-full">
          <thead className="bg-table-header-bg-color">
            <tr>
              <th className="py-4 px-10 text-sm font-semibold text-left">
                Instance Type
              </th>
              <th className="py-4 px-10 text-sm font-semibold text-left">
                Type
              </th>
              <th className="py-4 px-10 text-sm font-semibold text-left">
                Date & Time
              </th>
              <th className="py-4 px-7 text-sm font-semibold text-left">
                Status
              </th>
              <th className="py-4 px-16 text-sm font-semibold text-left">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {currentInstances?.map((ele, i) => (
              <tr
                key={ele.instance_id}
                className={`${
                  i % 2 === 0 ? "bg-white" : "bg-light-gray-1"
                } w-full`}
              >
                <td className="py-4 px-10 text-sm font-normal">
                  {ele.plan_name}
                </td>
                <td className="py-4 px-10 text-sm font-normal">
                  {ele.instance_type.toUpperCase()}
                </td>
                <td className="py-4 px-2 text-sm font-normal">
                  {convertToLocalTime(ele.instance_launch_time)}
                </td>
                <td className="py-4 text-sm font-normal">
                  <span
                    className={`ml-4 rounded-lg inline-flex items-center py-1 px-2 whitespace-nowrap font-semibold ${
                      ele.status === "intializing"
                        ? "bg-green-100 text-green-500"
                        : ele.status === "terminated"
                        ? "bg-red-100 text-red-500"
                        : ele.status === "running"
                        ? "bg-blue-100 text-blue-500"
                        : "text-black" // default color if none match
                    }`}
                  >
                    {ele.status}
                  </span>
                </td>
                {ele.status === "running" ? (
                  <td className="py-2 px-10 flex items-center">
                    <div className="mr-4">
                      <Tooltip title="Stop" placement="left" arrow>
                        <button
                          className="mt-1"
                          disabled={launchingInstance}
                          onClick={() => handleStop(ele)}
                        >
                          <StopCircleOutlined
                            className="h-5 w-5 stroke-[0.5]"
                            style={{ color: "red" }}
                          />
                        </button>
                      </Tooltip>
                    </div>

                    <div className="mr-4">
                      <Tooltip title="View" placement="left" arrow>
                        <button
                          className="mt-2"
                          onClick={() => handleOpen(ele)}
                        >
                          <GoLinkExternal className="h-5 w-5 stroke-[0.5]" />
                        </button>
                      </Tooltip>
                    </div>
                    <Tooltip title="Delete" placement="right" arrow>
                      <button
                        onClick={() => {
                          setShowConfirmationModal(true);
                          setDeleteIns(ele);
                        }}
                      >
                        <FaRegTrashAlt className="h-5 w-5" />
                      </button>
                    </Tooltip>
                    {/* Confirmation Modal */}
                    {showConfirmationModal && deleteIns === ele && (
                      <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                          <div
                            className="fixed inset-0 transition-opacity"
                            aria-hidden="true"
                          >
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                          </div>
                          <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true"
                          >
                            &#8203;
                          </span>
                          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div>
                              <div className="mt-3 text-center sm:mt-5">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                  Are you sure you want to delete?
                                </h3>
                              </div>
                              <div className="mt-5 sm:mt-6 flex justify-center">
                                <button
                                  type="button"
                                  className="cancel-button"
                                  onClick={() => {
                                    setShowConfirmationModal(false);
                                    deleteInstance(deleteIns);
                                  }}
                                >
                                  Delete
                                </button>
                                <button
                                  type="button"
                                  className="general-button ml-5"
                                  onClick={() =>
                                    setShowConfirmationModal(false)
                                  }
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </td>
                ) : ele.status === "stopped" ? (
                  <td className="py-2 px-10 flex items-center">
                    <div className="mr-4">
                      <Tooltip title="Start" placement="left" arrow>
                        <button
                          disabled={launchingInstance}
                          onClick={() => handleStart(ele)}
                        >
                          <PlayCircleOutlineOutlined
                            className="h-5 w-5 stroke-[0.5]"
                            style={{ color: "green" }}
                          />
                        </button>
                      </Tooltip>
                    </div>
                    <div className="mr-4">
                      <button className="mt-2">
                        <TbExternalLinkOff className="h-5 w-5 text-gray-400" />
                      </button>
                    </div>
                    <Tooltip title="Delete" placement="right" arrow>
                      <button
                        onClick={() => {
                          setShowConfirmationModal(true);
                          setDeleteIns(ele);
                        }}
                      >
                        <FaRegTrashAlt className="h-5 w-5" />
                      </button>
                    </Tooltip>
                    {/* Confirmation Modal */}
                    {showConfirmationModal && deleteIns === ele && (
                      <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                          <div
                            className="fixed inset-0 transition-opacity"
                            aria-hidden="true"
                          >
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                          </div>
                          <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true"
                          >
                            &#8203;
                          </span>
                          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div>
                              <div className="mt-3 text-center sm:mt-5">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                  Are you sure you want to delete?
                                </h3>
                              </div>
                              <div className="mt-5 sm:mt-6 flex justify-center">
                                <button
                                  type="button"
                                  className="cancel-button"
                                  onClick={() => {
                                    setShowConfirmationModal(false);
                                    deleteInstance(deleteIns);
                                  }}
                                >
                                  Delete
                                </button>
                                <button
                                  type="button"
                                  className="general-button ml-5"
                                  onClick={() =>
                                    setShowConfirmationModal(false)
                                  }
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </td>
                ) : (
                  <td className="py-2 px-10 flex items-center">
                    <div className="mr-4">
                      <Tooltip title="Unknown" placement="left" arrow>
                        <button>
                          <InfoOutlined className="h-5 w-5 text-gray-400" />
                        </button>
                      </Tooltip>
                    </div>
                    <div className="mr-4">
                      <button className="mt-2">
                        <TbExternalLinkOff className="h-5 w-5 text-gray-400" />
                      </button>
                    </div>
                    <Tooltip title="Deleted" placement="right" arrow>
                      <button>
                        <TbTrashOff className="h-5 w-5 text-gray-400" />
                      </button>
                    </Tooltip>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {/* Pagination */}
        <div className=" bg-white flex justify-end items-center border-t border-gray-100 p-2 space-x-3">
          <div className="flex items-center space-x-2">
            <span className="text-sm font-medium">Rows Per Page:</span>
            <select
              className="border rounded px-2 py-1 text-sm bg-rgba(248, 249, 253, 0.5)"
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
            </select>
          </div>
          <div className="flex items-center space-x-4">
            <span className="text-sm">
              {currentInstances.length > 0
                ? `${(currentPage - 1) * pageSize + 1} - ${Math.min(
                    currentPage * pageSize,
                    myinstance.length
                  )} of ${myinstance.length}`
                : "0"}
            </span>
            <button
              className={`px-3 py-1 border rounded ${
                currentPage === 1 && "opacity-50 cursor-not-allowed"
              }`}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
            <span className="text-sm">{currentPage}</span>
            <button
              className={`px-3 py-1 border rounded ${
                currentPage === totalPages && "opacity-50 cursor-not-allowed"
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>
        </div>
        {loading && (
          <div>
            <div className="absolute inset-0 flex flex-col items-center justify-center z-[200]">
              <img src={loader} alt="Loading..." className="spinner" />
              <p className="font-medium text-lg text-white">Please wait...</p>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default InstanceTab;
