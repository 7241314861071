import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import { FaArrowLeft } from "react-icons/fa6";
import { FaColumns } from "react-icons/fa";
import axiosInstance from "../../../api/axios";
import IOColumnsDrawer from "./IOColumnsDrawer";

const ConnectModal = ({ goBack, setIsOpen, fileUrl, fileName, handleOutputData }) => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDataPrevModal, setIsDataPrevModal] = useState(false);
 const [closeConnectModal, setCloseConnectModal] =  useState(false);
 
  useEffect(() => {
    const fetchData = async () => {
      if (!fileUrl) return;

      try {
        setLoading(true);
        const response = await axiosInstance.post(
          "/nocode/get_csv_sample",
          {
            s3_url: fileUrl,
          }
        );
        const { columns, sample_data } = response.data.data;
        setColumns(columns);
        setTableData(sample_data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [fileUrl]);

  console.log(fileUrl);
  
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleColumnSelect = (columnName) => {
    setSelectedColumns((prev) => {
      if (prev.includes(columnName)) {
        return prev.filter((name) => name !== columnName);
      }
      return [...prev, columnName];
    });
  };

// const handleDataPreviewModal = () => {
//   setIsDataPrevModal(true);
// }

  return (
    <div>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mb-10">
        <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
        <div className="relative mx-auto my-auto max-w-3xl w-full z-50 flex">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <div>
                <h4 className="text-lg font-semibold">
                  <span className="flex items-center">
                    <FaArrowLeft
                      className="mr-2 cursor-pointer"
                      onClick={goBack}
                    />
                    Connect
                  </span>
                </h4>
                <p className="text-sm text-[#10001ACC] ml-7">{fileName}</p>
              </div>

              <button
                className="background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                type="button"
                onClick={() => setIsOpen(false)}
              >
                <MdClose className="w-5 h-5 mt-2" />
              </button>
            </div>

            <div className="flex justify-between items-center my-3 mx-4">
              <p className="font-semibold">Assign Column Labels</p>
              <div className="relative">
                <div
                  className="border border-[#10001ACC] rounded-lg h-[40px] w-[270px] text-sm py-[8px] px-[14px] flex justify-between items-center cursor-pointer"
                  onClick={toggleDrawer}
                >
                  <FaColumns />
                  <span>Select Input and Output Columns</span>
                </div>
              </div>
            </div>

            <div className="overflow-scroll p-5">
              {
                loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="min-w-full border rounded-md pb-4">
                  <thead className="bg-[#EFF0F8CC]">
                    <tr className="text-left text-sm text-[#10001A]">
                      {columns.map((column, index) => (
                        <th key={index} className="py-2 px-4">
                          {column}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((row, index) => (
                      <tr
                        key={index}
                        className={`bg-white hover:bg-gray-100 text-[#10001A] ${
                          index % 2 === 1 ? "bg-[#F8F9FD80]" : ""
                        }`}
                      >
                        {columns.map((column, colIndex) => (
                          <td
                            key={colIndex}
                            className="py-2 px-4 text-sm text-gray-700"
                          >
                            {row[column]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                )
              }

            </div>

            <div className="flex justify-between items-center p-4 border-t border-solid border-slate-200 rounded-b">
              <p>
                Avoid importing sensitive or personal information. Only text is
                imported.
              </p>
              {/* <button
                className="classic-button h-[40px] w-[140px] text-[18px] font-[600]"
                // onClick={() => setIsOpen(false)}
                onClick={handleDataPreviewModal}
                
              >
                Import
              </button> */}
            </div>
          </div>

          {/* IOColumnsDrawer Component */}
          {isDrawerOpen && (
            <IOColumnsDrawer
              isOpen={isDrawerOpen}
              setIsOpen={setIsOpen}
              onClose={() => setIsDrawerOpen(false)}
              columns={columns}
              selectedColumns={selectedColumns}
              onSelectColumn={handleColumnSelect}
              fileUrl={fileUrl}
              // onDataSubmit={handleOutputData}
              className="h-full w-1/3 bg-white shadow-lg overflow-scroll"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ConnectModal;
