import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import axiosInstance from "../../api/axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function AiQuantumEngine() {
  const [aiModel, setAiModel] = useState([]);

  const handleRefreshToken = async () => {
    try {
      await axiosInstance.post("/user/refresh_token");
      return true;
    } catch (error) {
      console.log("refresh token");
      return false;
    }
  };

  useEffect(() => {
    const apiURL = process.env.REACT_APP_HOMEPAGE_URL;
    const aiModelStudio = async () => {
      try {
        const response = await axiosInstance.get(
          `${apiURL}/ai-quantum-engine/`
        );
        setAiModel(response.data.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          const isRefresh = await handleRefreshToken();
          if (isRefresh) {
            aiModelStudio();
          }
        } else {
          console.error("Error:", error);
          toast(error.response.data.message);
        }
      }
    };
    aiModelStudio();
  }, []);

  const items = [];
  for (let i = 0; i < aiModel?.length; i += 2) {
    items.push(aiModel.slice(i, i + 2));
  }

  return (
    <div className="bg-white shadow-md rounded-lg flex flex-col justify-between cursor-pointer h-full">
      <div className="px-5 pt-5">
        <div className="flex gap-2">
          <h2 className="font-bold text-xl text-card-li-color">
            {aiModel[0]?.title}
          </h2>
          <span className="font-medium text-sm text-opacity-60 text-secondry-clr pt-1.5">
            | {aiModel?.length} Categories
          </span>
        </div>
        <p className="text-card-li-color font-normal text-sm">
          {aiModel[0]?.heading}
        </p>
      </div>
      <div>
        {items?.map((group, index) => (
          <Paper
            key={index}
            sx={{ background: "none", boxShadow: "none", padding: "20px" }}
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
              {group?.map((item, idx) => (
                <div key={idx} className="w-full">
                  <Link to={item?.redirect_link}>
                    <img
                      src={item?.image_url}
                      alt="abcIndiaHavePass"
                      className="h-[200px] w-full object-cover rounded-lg"
                    />
                    <div className="bg-[#1A1A1A] bg-opacity-75 text-white py-3 -mt-11 w-full rounded-b-xl relative">
                      <p className="text-center font-semibold text-sm">
                        {item?.categories}
                      </p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </Paper>
        ))}
      </div>
    </div>
  );
}

export default AiQuantumEngine;
