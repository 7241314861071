import React from "react";

const DialogueCard = ({ show, text }) => {
  return (
    <div
      className={`${
        show ? "block" : "hidden"
      } relative p-4 bg-white rounded-lg shadow-md z-40 max-w-full text-xs sm:text-base sm:w-64 whitespace-pre-wrap break-words transition-transform duration-300 ease-in-out hover:scale-105`}
      style={{ overflowWrap: "anywhere" }}
    >
      {/* Arrow pointing to the left */}
      <div className="absolute left-[-15px] top-1/2 transform -translate-y-1/2 w-0 h-0 border-t-[10px] border-t-transparent border-b-[10px] border-b-transparent border-r-[15px] border-r-white"></div>

      {/* Dialogue Text */}
      <p className="text-gray-700 font-medium text-sm whitespace-pre-wrap break-words">
        {text}
      </p>
    </div>
  );
};

export default DialogueCard;
