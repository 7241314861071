import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getModelHomeCards } from "../../../services/api";
import Loader from "../../../assets/aiModels/QLoader.svg";
import { IoToggle } from "react-icons/io5";
import { BsToggleOff, BsSearch } from "react-icons/bs";

const AIModels = () => {
  const [cardData, setCardData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    (async function fetchCards() {
      try {
        const response = await getModelHomeCards();
        setCardData(response.data.data);
        setFilteredData(response.data.data.slice(0, 8));
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
    setFilteredData(isExpanded ? cardData.slice(0, 8) : cardData);
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = cardData.filter((item) =>
      item.categories.toLowerCase().includes(query)
    );
    setFilteredData(isExpanded ? filtered : filtered.slice(0, 8));
  };

  if (!cardData.length) {
    return (
      <div className="min-h-[600px] w-full bg-white px-4 md:px-8 pb-8 shadow-md flex justify-center">
        <img
          src={Loader}
          className="h-16 md:h-20 animate-spin mt-40"
          alt="Loading"
        />
      </div>
    );
  }

  return (
    <div className="min-h-[600px] w-full bg-white px-4 md:px-8 pb-8 shadow-md">
      {/* Header and Description */}
      <div className="flex flex-col md:flex-row items-center justify-between mb-4">
        <div className="flex items-center mb-4 md:mb-0">
          <p className="text-lg md:text-xl font-bold py-2 md:py-4 mr-2">
            AI Models
          </p>
          <p className="text-sm md:text-base text-gray-600 font-medium py-2 md:py-4">
            | {filteredData.length} categories
          </p>
        </div>
        {/* Search and Expand All Toggle */}
        <div className="flex items-center gap-3 md:gap-4">
          <div className="relative w-40 md:w-60">
            <BsSearch className="absolute left-2 top-2.5 text-gray-500" />
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearch}
              className="border p-2 pl-8 rounded-md text-sm w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <label className="flex items-center cursor-pointer text-sm md:text-base">
            <span className="mr-2 -mt-1">Explore All Models</span>
            <div onClick={toggleExpand} className="toggle-icon">
              {isExpanded ? (
                <IoToggle size={26} />
              ) : (
                <BsToggleOff size={26} className="text-gray-500" />
              )}
            </div>
          </label>
        </div>
      </div>

      {/* Description Text */}
      <p className="text-sm md:text-base font-medium mb-4 -mt-2">
      Unlock a world of AI possibilities! Explore fascinating projects for
        <span className="text-green-600 font-semibold"> just $5.</span> Design
        logos, write code, and more for
        <span className="text-green-600 font-semibold">
          {" "}
          only $0.01 per request
        </span>{" "}
        – that's 500 requests for
        <span className="text-green-600 font-semibold"> $5.</span>
      </p>

      <div className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-4">
        {filteredData.map((item, index) => (
          <Link key={index} to={`/modelStudio/${item.categories}`}>
            <div className="h-48 w-full rounded-lg relative overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300">
              <img
                src={item.image_url}
                alt={item.categories}
                className="w-full h-full object-cover"
              />
              <div className="absolute bottom-0 h-12 w-full bg-black bg-opacity-60 flex justify-center items-center">
                <p className="text-white text-sm md:text-base">
                  {item.categories}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AIModels;
