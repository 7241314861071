import { TextField, styled } from "@mui/material";
import React, { useContext, useState } from "react";
import { ImSpinner3 } from "react-icons/im";
import { toast } from "react-toastify";
import {
  getInstanceStatus,
  getInstanceStatusStopper,
  getStatusLight,
  getTextToVideo,
} from "../../../services/api";
import { Context } from "../../../Context";
import StepsSlider from "../../StepsSlider";
import Slider from "../../Slider";
import axiosInstance from "../../../api/axios";
import AddCreditModal from "../../AddCreditModal";

const Query = styled(TextField)({
  backgroundColor: "white",
  width: "100%",
});

const TextToVideo = ({ endpoint, isActive, ins_id, modelName }) => {
  const { setModelStatusLight } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [initialising, setInitialising] = useState(false);

  const [QueryText, setQueryText] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [numInferenceSteps, setNumInferenceSteps] = useState(8);
  const [numFrames, setNumFrames] = useState(25);
  const [isOpen, setIsOpen] = useState(false);

  const allowedSteps = [1, 2, 4, 8];

  const checkInstance = async () => {
    setApiResponse("");
    if (!QueryText) {
      toast.warning("User Query Required!");
      return;
    }
    setInitialising(true);
    setModelStatusLight("initialising");

    try {
      // Step 1: Check for available credits (GET request)
      const rescheck = await axiosInstance.get(
        `payments/availableCredit/?model-name=${modelName}`
      );

      if (rescheck.data.data.status) {
        // Step 2: Credit is available, proceed to deduct it (POST request)
        const deductCredit = await axiosInstance.post(
          "payments/availableCredit/",
          {
            model_name: modelName,
            add: false,
            sub: true,
            amount: 0,
          }
        );

        if (deductCredit.data.data.status) {
          // Proceed with the instance status check since credit deduction was successful
          const res = await getInstanceStatus({ instance_id: ins_id });
          console.log(res);

          if (res.status === 200) {
            setLoading(true);
            setInitialising(false);

            try {
              const responseLight = await getStatusLight({
                instance_id: ins_id,
              });
              setModelStatusLight(responseLight.data.status);
            } catch (error) {
              console.log(error.message);
              await refundCredit(); // Refund credit if something goes wrong
            }

            // Trigger handleClick for text generation
            handleClick(deductCredit);
          }
        } else {
          // Failed to deduct credit, stop further execution
          toast.error(deductCredit.data.message);
          setLoading(false);
          setInitialising(false);
          return false; // Stop further execution
        }
      } else {
        // If no credit, show warning and stop the process
        toast.error(rescheck.data.message);
        setIsOpen(true); // Open AddCreditModal when no credit
        setLoading(false);
        setInitialising(false);
        return false; // Stop further execution
      }
    } catch (error) {
      toast.error("Something Went Wrong,Please Try Again!");
      setLoading(false);
      setInitialising(false);
    }
  };

  // Function to refund credit
  const refundCredit = async () => {
    try {
      await axiosInstance.post("payments/availableCredit/", {
        model_name: modelName,
        add: true,
        sub: false,
        amount: 0,
      });
      console.log("Credit refunded successfully");
    } catch (error) {
      console.log("Failed to refund credit:", error.message);
    }
  };

  const handleClick = async (deductCredit) => {
    setApiResponse("");
    if (!QueryText) {
      toast.warning("User Query Required!");
      return;
    }
    setApiResponse("");

    try {
      const response = await getTextToVideo(endpoint, {
        user_query: QueryText,
      });
      setApiResponse(response.data.response);
      toast.success(deductCredit.data.message);
      if (
        deductCredit.data.message ===
        "You have used 1 out of 1 free inferencing credits."
      ) {
        infInstanceStatus();
      }
    } catch (error) {
      console.error("Error generating Video:", error);
      await refundCredit(); // Refund credit on error
    } finally {
      setLoading(false);
      const res = await getInstanceStatusStopper({ instance_id: ins_id });
      console.log(res);
    }
  };

  const handleSliderChange = (e) => {
    const value = parseInt(e.target.value, 10);
    const closestStep = allowedSteps.reduce((prev, curr) =>
      Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
    );
    setNumInferenceSteps(closestStep);
  };

  const infInstanceStatus = async () => {
    try {
      await axiosInstance.post("payments/updateStatus/", {
        all_free_credits_used: true,
      });
      console.log("Credit refunded successfully");
    } catch (error) {
      console.log("Failed to refund credit:", error.message);
    }
  };

  return (
    <div className="flex w-full h-full">
      {/* User Interaction Section */}
      <div className="w-1/2 h-full p-8">
        <p className="text-[16px] font-[600] mb-8">Generate Response</p>
        <p className="text-[15px] font-[500] -mt-6 mb-6">
          Running this model will deduct{" "}
          <span className="text-[#008B16] font-[800]">$0.01</span> / request
          from your balance.{" "}
        </p>
        {/* User Query TextField */}
        <Query
          className=""
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.shiftKey) {
              e.preventDefault();
              setQueryText((prevValue) => prevValue + "\n");
            } else if (e.key === "Enter") {
              e.preventDefault();
              setQueryText(e.target.value);
              checkInstance();
            }
          }}
          value={QueryText}
          label="User Query"
          placeholder={`${
            modelName === "AnimateDiff-Lightning-Realistic"
              ? "Closeup of tony stark, fireworks in the background, high quality."
              : "A girl smiling, background neon lights"
          }`}
          onChange={(e) => setQueryText(e.target.value)}
          inputProps={{
            style: {
              height: "80px",
            },
          }}
        />

        <StepsSlider
          label={"Number Inference Steps"}
          value={numInferenceSteps}
          setValue={setNumInferenceSteps}
          min={1}
          max={8}
          step={1}
          handleChange={handleSliderChange}
          text={
            "The number of denoising steps. More denoising steps usually lead to a higher quality video at the expense of slower inference."
          }
        />
        <Slider
          label={"Number of Frames"}
          value={numFrames}
          min={10}
          max={25}
          step={1}
          setValue={setNumFrames}
          text={
            "The num_frames parameter determines how many video frames are generated per second."
          }
        />

        {loading ? (
          <button className="loading-button w-[136px] h-[52px] mx-auto">
            <ImSpinner3 className="text-xl mr-1 animate-spin" />
            Running
          </button>
        ) : initialising ? (
          <button className="loading-button w-[136px] h-[52px] mx-auto">
            <ImSpinner3 className="text-xl mr-1 animate-spin" />
            Initializing
          </button>
        ) : (
          <button
            disabled={!isActive}
            className={`${
              isActive ? "classic-button" : "disabled-button"
            } w-[136px] h-[52px] mx-auto `}
            onClick={() => checkInstance()}
          >
            Run
          </button>
        )}
        {initialising && (
          <p className="text-gen-color mt-4 text-[14px] font-[600]">
            The Model instance is initializing, This will take 1 to 2 mins. Once
            the instance is active we will process your request
          </p>
        )}
      </div>
      {/* Response Section */}
      <div className="w-1/2 min-h-[538px] p-8 border-l-2 ">
        <p className="text-[16px] font-[600] mb-8">Response</p>
        {apiResponse && (
          <img
            src={`data:image/gif;base64,${apiResponse}`}
            className="max-h-[375px] mx-auto"
            alt="Generated Content"
          />
        )}
      </div>
      {/* Add Credit Modal */}
      <AddCreditModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default TextToVideo;
