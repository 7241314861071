import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";
import axios from "axios";
import axiosInstance from "../../api/axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function AiModelStudio() {
  const [aiModel, setAiModel] = useState([]);
  const apiURL = process.env.REACT_APP_HOMEPAGE_URL;
  const [carouselIndex, setCarouselIndex] = useState(0);
  const handleRefreshToken = async () => {
    try {
      await axiosInstance.post("/user/refresh_token");
      return true;
    } catch (error) {
      console.log("refresh token");
      // handleLogOut()
      return false;
    }
  };

  useEffect(() => {
    const aiModelStudio = async () => {
      try {
        const response = await axiosInstance.get(`${apiURL}/ai-model-studio/`);
        setAiModel(response.data.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          const isRefresh = await handleRefreshToken();
          if (isRefresh) {
            aiModelStudio();
          }
        } else {
          console.error("Error:", error);
          toast(error.response.data.message);
        }
      } finally {
      }
    };
    aiModelStudio();
  }, [apiURL]);

  const items = [];
  for (let i = 0; i < aiModel?.length; i += 4) {
    items.push(aiModel.slice(i, i + 4));
  }

  // const handleCardClick = (filterName) => {
  //     setAiModelFilter(filterName)
  //     localStorage.setItem("aiModelFilter", filterName)
  //     navigate('/aimodel')
  // }

  const handleNext = () => {
    setCarouselIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  return (
    <div className="bg-white shadow-md rounded-lg w-full max-w-screen-lg mx-auto justify-between cursor-pointer mt-3">
      <div className="px-5 pt-5 pb-3">
        <div className="flex flex-wrap items-center gap-2">
          <h2 className="font-bold text-xl text-card-li-color">
            {aiModel[0]?.title}
          </h2>
          <span className="font-medium text-sm text-opacity-60 text-secondry-clr pt-1.5">
            | {aiModel?.length} Categories
          </span>
        </div>
        <p className="text-card-li-color font-normal text-sm">
          {aiModel[0]?.heading}
        </p>
      </div>
      <Carousel
        index={carouselIndex}
        navButtonsAlwaysVisible={true}
        animation="slide"
        autoPlay={false}
        indicators={false}
        cycleNavigation={true}
        navButtonsProps={{
          style: {
            backgroundColor: "#F2F2F2",
            color: "#282828",
          },
        }}
        next={() => handleNext()}
        prev={() => handleNext()}
      >
        {items?.map((group, index) => (
          <Paper
            key={index}
            sx={{
              background: "none",
              boxShadow: "none",
              padding: "20px",
              minHeight: "250px",
            }}
          >
            <div className="grid grid-cols-4 gap-5">
              {group?.map((item, idx) => (
                <Link
                  key={idx}
                  to={`/modelStudio/${item.categories}`}
                  className="block"
                >
                  <div key={idx} style={{ height: "200px" }} className="">
                    <img
                      src={item?.image_url}
                      alt="abcIndiaHavePass"
                      className="h-[200px] w-full object-cover rounded-xl"
                    />
                    <div className="bg-[#1A1A1A] bg-opacity-75 text-white py-3 -mt-11 w-full rounded-b-xl relative">
                      <p className="text-center font-semibold text-sm">
                        {item?.categories}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </Paper>
        ))}
      </Carousel>
    </div>
  );
}

export default AiModelStudio;
