import React, { useContext, useRef, useEffect } from "react";
import { MdClose } from "react-icons/md";
import { Context } from "../Context";
import axiosInstance from "../api/axios";
import { toast } from "react-toastify";

const AddCreditModal = ({ isOpen, setIsOpen }) => {
  
  const { addCredits, setAddCredits } = useContext(Context); 
  const dropdownRef = useRef(null);

  const addCredit = async (credit) => {
    const data = {
      amount: credit,
    };
    try {
      const response = await axiosInstance.post(
        "/payments/create-checkout-session/",
        data
      );
      window.open(response.data.data.checkout_url, "_blank");
      console.log(response);

      setAddCredits(false);
      setIsOpen(false); 
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const isRefresh = await handleRefreshToken();
        if (isRefresh) {
          addCredit(credit);
        }
      } else {
        console.error("Error:", error);
        toast(error.response.data.message);
      }
    }
  };

  const handleRefreshToken = async () => {
    try {
      await axiosInstance.post("/newuser/api/refresh");
      return true;
    } catch (error) {
      console.log("refresh token");
      return false;
    }
  };

  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown);
    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, []);

  if (!isOpen) return null;

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto" ref={dropdownRef}>
          {/* Modal content */}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/* Header */}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <div>
                <h5 className="text-xl font-semibold">Add Credits</h5>
                <p className="text-sm">Choose any one option</p>
              </div>
              <button
                className="background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setIsOpen(false)} // Close modal on close button
              >
                <MdClose className="w-5 h-5 mt-2" />
              </button>
            </div>
            {/* Body */}
            <div className="flex justify-between gap-6 p-6">
              {/* $5 Credit Option */}
              <div className="flex group items-center justify-center w-64 p-6 border-2 bg-[#feffff] border-gray-100 rounded-lg hover:bg-[#F9F4FC]">
                <div className="text-center">
                  <p className="text-Quantum-pre-color text-2xl font-semibold pb-5">
                    $5
                  </p>
                  <h5 className="text-heading-black text-4xl font-semibold">
                    5
                  </h5>
                  <p className="text-Quantum-pre-color text-base font-medium mt-2">
                    Credits
                  </p>
                  <div className="text-center mt-5">
                    <button
                      className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1] text-[#10001ACC] rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px]"
                      onClick={() => addCredit(5)}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
              {/* $10 Credit Option */}
              <div className="flex group items-center justify-center w-64 p-6 border-2 bg-[#feffff] border-gray-100 rounded-lg hover:bg-[#F9F4FC]">
                <div className="text-center">
                  <p className="text-Quantum-pre-color text-2xl font-semibold pb-5">
                    $10
                  </p>
                  <h5 className="text-heading-black text-4xl font-semibold">
                    10
                  </h5>
                  <p className="text-Quantum-pre-color text-base font-medium mt-2">
                    Credits
                  </p>
                  <div className="text-center mt-5">
                    <button
                      className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1] text-[#10001ACC] rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px]"
                      onClick={() => addCredit(10)}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
              {/* $30 Credit Option */}
              <div className="flex group items-center justify-center w-64 p-6 border-2 bg-[#feffff] border-gray-100 rounded-lg hover:bg-[#F9F4FC]">
                <div className="text-center">
                  <p className="text-Quantum-pre-color text-2xl font-semibold pb-5">
                    $30
                  </p>
                  <h5 className="text-heading-black text-4xl font-semibold">
                    30
                  </h5>
                  <p className="text-Quantum-pre-color text-base font-medium mt-2">
                    Credits
                  </p>
                  <div className="text-center mt-5">
                    <button
                      className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1] text-[#10001ACC] rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px]"
                      onClick={() => addCredit(30)}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
              {/* $50 Credit Option */}
              <div className="flex group items-center justify-center w-64 p-6 border-2 bg-[#feffff] border-gray-100 rounded-lg hover:bg-[#F9F4FC]">
                <div className="text-center">
                  <p className="text-Quantum-pre-color text-2xl font-semibold pb-5">
                    $50
                  </p>
                  <h5 className="text-heading-black text-4xl font-semibold">
                    50
                  </h5>
                  <p className="text-Quantum-pre-color text-base font-medium mt-2">
                    Credits
                  </p>
                  <div className="text-center mt-5">
                    <button
                      className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1] text-[#10001ACC] rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[40px] py-[18px]"
                      onClick={() => addCredit(50)}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AddCreditModal;
