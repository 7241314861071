import React, { useEffect, useState } from 'react'
import CostAnalysis from './CostAnalysis';
import BillingPayment from './BillingPayment';
import Overview from './Overview';
// import Projects from './Projects';

function Tab() {
    const [activeTab, setActiveTab] = useState(parseInt(localStorage.getItem('activeTab')) || 1);
    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
        // Save the active tab to localStorage
        localStorage.setItem('activeTab', tabNumber);
        window.location.hash = `#tab${tabNumber}`;
    };

    useEffect(() => {
        const handleHashChange = () => {
            const hash = window.location.hash.slice(4);
            const tabNumber = parseInt(hash) || 1;
            setActiveTab(tabNumber);
            // Save the active tab to localStorage
            localStorage.setItem('activeTab', tabNumber);
        };

        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full">
                    <ul
                        className="flex list-none flex-wrap flex-row m-auto w-2/4"
                        role="tablist"
                    >
                        <li className="flex-auto text-center cursor-pointer">
                            <div
                                className={
                                    "text-lg font-semibold  py-7  rounded-t-xl " +
                                    (activeTab === 1 ? 'active text-tab-active-color bg-white z-50 ' : 'text-gray-60 bg-[#E8E9EE] translate-y-[10%] rounded-tr-none  z-10 ')
                                }
                                onClick={() => handleTabClick(1)}
                            >
                                Information
                            </div>
                        </li>

                        {/* <li className="flex-auto text-center cursor-pointer">
                            <div
                                className={
                                    "text-lg py-7 block border-x border-gray-50" +
                                    (activeTab === 2 ? 'active text-tab-active-color rounded-t-2xl bg-white font-bold' : 'text-gray-60 bg-tab-not-active-color font-medium')
                                }
                                onClick={() => handleTabClick(2)}
                            >
                                Projects
                            </div>
                        </li> */}

                        <li className="flex-auto text-center cursor-pointer">
                            <div
                                className={
                                    "text-lg font-semibold py-7 rounded-t-xl " +
                                    (activeTab === 3 ? 'active text-tab-active-color bg-white z-50 font-bold' : 'text-gray-60 bg-[#E8E9EE] translate-y-[10%] rounded-t-none   z-10 ')
                                }
                                onClick={() => handleTabClick(3)}
                            >
                                Billing
                            </div>
                        </li>

                        {/* <li className="flex-auto text-center cursor-pointer">
                            <div
                                className={
                                    "text-lg font-semibold py-7 rounded-t-xl " +
                                    (activeTab === 4 ? 'active text-tab-active-color bg-white z-50 font-bold' : 'text-gray-60 bg-[#E8E9EE] translate-y-[10%] rounded-tl-none   z-10 ')
                                }
                                onClick={() => handleTabClick(4)}
                            >
                                User Logs
                            </div>
                        </li> */}
                    </ul>

                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                        <div className="px-4 py-5 flex-auto mb-14">
                            <div className="tab-content tab-space">
                                <div className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}>
                                    <Overview />
                                </div>
                                {/* <div className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}>
                                    <Projects />
                                </div> */}
                                <div className={`tab-pane ${activeTab === 3 ? 'active' : ''}`}>
                                    <BillingPayment />
                                </div>
                                <div className={`tab-pane ${activeTab === 4 ? 'active' : ''}`}>
                                    <CostAnalysis />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Tab