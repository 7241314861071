import React, { useEffect, useRef, useState } from "react";
import { CiSquarePlus } from "react-icons/ci";
import { BsSearch } from "react-icons/bs";
import AddHuggingModal from "./AddHuggingModal";
import { FaRegTrashAlt } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";
import { LuClipboardEdit } from "react-icons/lu";
import { AiOutlineInteraction } from "react-icons/ai";
import { HiDotsVertical } from "react-icons/hi";
import { FaRegPlayCircle } from "react-icons/fa";
import { IoMdCode } from "react-icons/io";
import axiosInstance from "../../../api/axios";
import { IoCodeSharp } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import Loader from "../../../assets/aiModels/QLoader.svg";

const HuggingFaceModels = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showActionsForModel, setShowActionsForModel] = useState(null);
  const actionRef = useRef(null);
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modelsLoading, setModelsLoading] = useState(false);
  const [modelsError, setModelsError] = useState(null);
  const navigate = useNavigate();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [deleteTunedModel, setDeleteTunedModel] = useState(null);

  const handleAddHuggingModel = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const LoaderComponent = () => (
    <ClipLoader color={"#000"} loading={true} size={24} />
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionRef.current && !actionRef.current.contains(event.target)) {
        setShowActionsForModel(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleRefreshToken = async () => {
    try {
      await axiosInstance.post("/newuser/api/refresh");
      return true;
    } catch (error) {
      console.log("Refresh token error:", error);
      return false;
    }
  };

  const getCodeModel = (modelId, modelIp,modelType) => {
    navigate("/getCode", { state: { modelIp, modelId,modelType } });
  };

  const getUiModel = (model, modelIp) => {
    navigate("/getUi", { state: { model, modelIp } });
  };

  const getRagUiModel = (model, modelIp) => {
    navigate("/getRagUi", { state: { model, modelIp } });
  };


  // Function to handle the Start Deployment API
  const handleStartDeploy = async (model) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        "/tune/start_deploy_huggingmodel",
        {
          model_name: model?.model_name,
        }
      );
      window.location.reload();
      console.log("Start deployment success:", response.data);
      // Handle success (e.g., update UI or model status)
    } catch (error) {
      console.error("Error starting deployment:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle the Stop Deployment API
  const handleStopDeploy = async (model) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post("/tune/stop_deploy", {
        model_name: model?.model_name,
      });
      window.location.reload();
      console.log("Stop deployment success:", response.data);
      // Handle success (e.g., update UI or model status)
    } catch (error) {
      console.error("Error stopping deployment:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to open the confirmation modal
  const handleDeleteModel = (modelId, model) => {
    console.log("Model ID to delete:", modelId); // Debugging
    setDeleteTunedModel(model); // Store the ID of the model to delete
    setShowDeleteConfirmationModal(true); // Show the modal
  };

  // Function to delete the model and close the modal
  const confirmDeleteModel = () => {
    console.log("Confirm delete for model ID:", deleteTunedModel); // Debugging
    if (deleteTunedModel) {
      setShowDeleteConfirmationModal(false); // Close the modal
      deleteModel(deleteTunedModel); // Call delete function with the stored model ID
    }
  };

  // Function to delete the model using the API
  const deleteModel = async (model) => {
    console.log("Deleting model ID:", model); // Debugging

    setLoading(true);

    // Prepare request payload
    const requestData = {
      model_name: model?.model_name,
      type: model?.type,
    };

    // Determine API endpoint based on model type
    let apiEndpoint = "/tune/delete_deploy";
    // if (model?.type === "tuned model") {
    //   apiEndpoint = '/nocode/delete-tuned-model';
    // } else if (model?.type === "Deployed model") {
    //   apiEndpoint = '/tune/delete_deploy';
    // } else {
    //   console.error("Invalid model type:", model?.type);
    //   toast("Invalid model type");
    //   setLoading(false);
    //   return; // Exit if model type is not valid
    // }

    try {
      const response = await axiosInstance.delete(apiEndpoint, {
        data: requestData,
      });
      console.log("Delete response:", response); // Debugging
      toast(response?.data?.message);
      loadModels(); // Reload models after deletion
    } catch (error) {
      console.error("Error deleting model:", error); // Debugging
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          deleteModel(model); // Retry after refreshing token
        }
      } else {
        console.error("Error deleting model:", error);
        toast(error.response?.data?.message || "Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };

  const loadModels = async () => {
    setModelsLoading(true);
    try {
      const response = await axiosInstance.get(
        "/nocode/get-models?type=huggingface%20model"
      );
      const modelsData = response?.data?.data;
      setModels(
        response.data.success && modelsData?.length > 0 ? modelsData : []
      );
    } catch (error) {
      if (error.response?.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) loadModels();
      } else {
        setModelsError(error.message || "Failed to fetch models");
      }
    } finally {
      setModelsLoading(false);
    }
  };

  useEffect(() => {
    loadModels();
    const interval = setInterval(loadModels, 30000);
    return () => clearInterval(interval);
  }, []);

  // if (!models.length) {
  //   return (
  //     <div className="min-h-[600px] w-full bg-white px-4 md:px-8 pb-8 shadow-md flex justify-center">
  //       <img
  //         src={Loader}
  //         className="h-16 md:h-20 animate-spin mt-40"
  //         alt="Loading"
  //       />
  //     </div>
  //   );
  // }
  return (
    <div className="min-h-[600px] w-full bg-white px-8 pb-8 shadow-md">
      <div className="flex flex-col sm:flex-row sm:justify-between items-center py-4 space-y-4 sm:space-y-0">
        {/* Title */}
        {/* <p className="text-lg sm:text-xl font-semibold">Hugging Face Models</p> */}
        {/* Add Model Button */}
        <div
          className="flex items-center justify-center w-full sm:w-auto p-2 border border-gray-600 rounded-md font-medium cursor-pointer"
          onClick={handleAddHuggingModel}
        >
          <CiSquarePlus className="text-xl" />
          <span className="ml-1 text-sm">Add Model</span>
        </div>

        {/* Search and Add Model Section */}
        <div className="flex flex-col sm:flex-row items-center sm:space-x-4 w-full sm:w-auto space-y-4 sm:space-y-0">
          {/* Search Bar */}
          <div className="relative w-full sm:w-48">
            <BsSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500" />
            <input
              type="text"
              placeholder="Search"
              className="border p-2 pl-8 rounded-md text-sm w-full"
            />
          </div>
        </div>
      </div>

      <div className="border-2 rounded-[8px] mt-2">
        <div className="min-h-[350px] max-h-[545px] rounded-[8px] bg-white overflow-auto">
          <table className="w-full border-collapse">
            <thead className="sticky top-0 bg-[#EFF0F8] border-b rounded-t-[8px]">
              <tr>
                <th className="px-10 py-4 text-start text-[15px] font-[600]">
                  Model Name
                </th>
                <th className="px-10 py-4 text-start text-[15px] font-[600]">
                  Model Id
                </th>
                <th className="px-10 py-4 text-start text-[15px] font-[600]">
                  GPU
                </th>
                <th className="px-10 py-4 text-center text-[15px] font-[600]">
                  GPU Count
                </th>
                <th className="py-4 px-10 text-sm font-semibold text-center">
                  Status
                </th>
                <th className="px-10 py-4 text-start text-[15px] font-[600]">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="max-h-[500px] overflow-y-auto">
              {models.length > 0
                ? models.map((model) => (
                    <tr key={model._id} className="w-full">
                      <td className="py-4 px-10 text-sm font-normal">
                        {model.model_name}
                      </td>
                      <td className="py-4 px-10 text-sm font-normal">
                        {model.model_id || "-"}
                      </td>
                      <td className="py-4 px-10 text-sm font-normal">
                        {model.feature.gpu_name || "N/A"}
                      </td>
                      <td className="py-4 px-10 text-sm font-normal text-center">
                        {model.feature.number_of_gpu || 0}
                      </td>
                      <td className="text-sm font-medium text-center">
                        <span
                          className={` rounded-lg py-1 px-2 inline-flex items-center whitespace-nowrap font-semibold ${
                            model.status === "Tuning In-progress"
                              ? "bg-yellow-100 text-yellow-500"
                              : model.status === "Container Created"
                              ? "bg-sky-100 text-sky-500"
                              : model.status === "Model Loading"
                              ? "bg-purple-100 text-purple-500"
                              : model.status === "Tuning Failed"
                              ? "bg-red-100 text-red-500"
                              : model.status === "Tuning Completed"
                              ? "bg-green-100 text-green-500"
                              : model.status === "Tuning Initialized"
                              ? "bg-pink-100 text-pink-500"
                              : model.status === "Deployment Ready"
                              ? "bg-blue-100 text-blue-500"
                              : model.status === "Deployment Initialized"
                              ? "bg-indigo-100 text-indigo-600"
                              : model.status === "Deployment Stopped"
                              ? "bg-red-100 text-red-500"
                              : model.status === "Deployment In-Progress"
                              ? "bg-orange-100 text-orange-500"
                              : "text-black py-4 px-10 text-sm font-normal"
                          }`}
                        >
                          {model.status}
                        </span>
                      </td>
                      <td className="relative">
                        <HiDotsVertical
                          className="mt-0.5 ml-12 cursor-pointer"
                          onClick={() =>
                            setShowActionsForModel(
                              showActionsForModel === model._id
                                ? null
                                : model._id
                            )
                          }
                        />
                        {showActionsForModel === model._id && (
                          <div
                            ref={actionRef}
                            className="absolute right-0 top-0 z-10 bg-white shadow-lg rounded-md p-2 mt-2 w-48 ring-1 ring-black ring-opacity-5"
                          >
                            {/* Start/Stop Button */}
                            {model.status === "Deployment Ready" ? (
                              <button
                                className={`flex items-center w-full text-base px-4 py-2 ${
                                  loading
                                    ? "text-gray-400 cursor-not-allowed"
                                    : "text-black"
                                }`}
                                onClick={() =>
                                  !loading && handleStopDeploy(model)
                                }
                                disabled={loading}
                              >
                                {loading ? (
                                  <LoaderComponent />
                                ) : (
                                  <FaRegPlayCircle className="mr-2 h-5 w-5" />
                                )}
                                {loading ? "Stopping..." : "Stop"}
                              </button>
                            ) : model.status === "Deployment Stopped" ? (
                              <button
                                className={`flex items-center w-full text-base px-4 py-2 ${
                                  loading
                                    ? "text-gray-400 cursor-not-allowed"
                                    : "text-black"
                                }`}
                                onClick={() =>
                                  !loading && handleStartDeploy(model)
                                }
                                disabled={loading}
                              >
                                {loading ? (
                                  <LoaderComponent />
                                ) : (
                                  <FaRegPlayCircle className="mr-2 h-5 w-5" />
                                )}
                                {loading ? "Starting..." : "Start / Stop"}
                              </button>
                            ) : (
                              <button
                                className="flex items-center w-full text-base px-4 py-2 text-gray-400 cursor-not-allowed"
                                disabled
                              >
                                <FaRegPlayCircle className="mr-2 h-5 w-5" />{" "}
                                Start / Stop
                              </button>
                            )}

                            <button
                              className={`flex items-center w-full text-base px-4 py-2 ${
                                model.status === "Deployment Ready"
                                  ? "text-black"
                                  : "text-gray-400 cursor-not-allowed"
                              }`}
                              disabled={model.status !== "Deployment Ready"}
                              onClick={() =>
                                getCodeModel(
                                  model._id,
                                  model.feature.service_ip,
                                  model.type
                                )
                              }
                            >
                              <IoCodeSharp className="mr-2 h-5 w-5" /> Get Code
                            </button>

                            <button
                              className={`flex items-center w-full text-base px-4 py-2 ${
                                model.status === "Deployment Ready"
                                  ? "text-black"
                                  : "text-gray-400 cursor-not-allowed"
                              }`}
                              disabled={model.status !== "Deployment Ready"}
                              onClick={() =>
                                getUiModel(model, model.feature.service_ip)
                              }
                            >
                              <IoCodeSharp className="mr-2 h-5 w-5" />{" "}
                              Interactive UI
                            </button>

                            {/* <button
                              className={`flex items-center w-full text-base px-4 py-2 ${
                                model.status === "Deployment Ready"
                                  ? "text-black"
                                  : "text-gray-400 cursor-not-allowed"
                              }`}
                              disabled={model.status !== "Deployment Ready"}
                              onClick={() =>
                                getRagUiModel(model, model.feature.service_ip)
                              }
                            >
                              <IoCodeSharp className="mr-2 h-5 w-5" />{" "}
                              Rag UI
                            </button> */}
                            <button
                              className={`flex items-center w-full text-base px-4 py-2 ${
                                model.status === "Tuning Failed" ||
                                model.status === "Tuning Completed" ||
                                model.status === "Deployment Ready"
                                  ? "text-black"
                                  : "text-gray-400 cursor-not-allowed"
                              }`}
                              disabled={
                                !(
                                  model.status === "Tuning Failed" ||
                                  model.status === "Tuning Completed" ||
                                  model.status === "Deployment Ready"
                                )
                              }
                              onClick={() =>
                                handleDeleteModel(model._id, model)
                              }
                            >
                              <FaRegTrashAlt className="mr-2 h-5 w-5" /> Delete
                            </button>
                          </div>
                        )}

                        {/* Confirmation Modal for Model Deletion */}
                        {showDeleteConfirmationModal &&
                          deleteTunedModel._id === model._id && (
                            <div className="fixed z-10 inset-0 overflow-y-auto">
                              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                <div
                                  className="fixed inset-0 transition-opacity"
                                  aria-hidden="true"
                                >
                                  <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                </div>
                                <span
                                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                  aria-hidden="true"
                                >
                                  &#8203;
                                </span>
                                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                  <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Are you sure you want to delete this
                                        model?
                                      </h3>
                                    </div>
                                    <div className="mt-5 sm:mt-6 flex justify-center">
                                      <button
                                        type="button"
                                        className="cancel-button"
                                        onClick={() => confirmDeleteModel()}
                                      >
                                        Delete
                                      </button>
                                      <button
                                        type="button"
                                        className="general-button ml-5"
                                        onClick={() =>
                                          setShowDeleteConfirmationModal(false)
                                        }
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                      </td>
                    </tr>
                  ))
                : 
                <tr>
                    <td colSpan={6} className="text-center text-lg font-normal py-4">No models found</td>
                  </tr>
                  }
              {
              // models.length === 0 && (
              //   <tr>
              //     <td
              //       colSpan={6}
              //       className="text-center py-4 border-2 dashed border-gray-400 cursor-pointer"
              //       onClick={handleAddHuggingModel}
              //     >
              //       <div className="flex justify-center items-center">
              //         <CiSquarePlus
              //           style={{ fontSize: "24px", marginRight: "8px" }}
              //         />
              //         <span>Add Model</span>
              //       </div>
              //     </td>
              //   </tr>
              // )
              }
            </tbody>
          </table>
        </div>
      </div>
      {isOpen && <AddHuggingModal isOpen={isOpen} setIsOpen={setIsOpen} />}
    </div>
  );
};

export default HuggingFaceModels;
