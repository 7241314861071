import React, { useState } from "react";

const Tooltip = ({ text }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="relative inline-block">
      {/* Tooltip Trigger */}
      <span
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        className="border border-gray-200 text-center rounded-full w-5 h-5 flex justify-center items-center bg-[#E9EAED] text-xs font-bold font-serif cursor-pointer"
      >
        i
      </span>

      {/* Tooltip */}
      <div
        className={`absolute z-10 xs:whitespace-wrap sm:h-auto sm:w-auto md:whitespace-nowrap px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm transition-opacity duration-200 ease-in-out ${
          show ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
        style={{
          top: "50%",
          left: "110%", // Position it slightly away from the icon
          transform: "translateY(-50%)", // Center it vertically with the icon
        }}
      >
        {text}
        <div
          className="absolute bg-white w-2 h-2 border border-gray-200 transform rotate-45"
          style={{
            top: "50%",
            left: "-5px", // Arrow positioned to point at the trigger icon
            transform: "translateY(-50%) rotate(45deg)", // Create a diamond arrow shape
          }}
        ></div>
      </div>
    </div>
  );
};

export default Tooltip;
