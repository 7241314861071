import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";

const IwantSection = () => {
  const sections = [
    {
      title: "Generate Image",
      link: "/modelStudio/Image Generation/Stable-Diffusion-3-Medium-Diffusers",
      description: ["Create a logo", "Create an image for your document"],
      bgColor: "bg-gray-50 border border-[#E7E7E7]",
    },
    {
      title: "Generate Document",
      link: "/modelStudio/Text Generation/Meta-Llama-3-8B-Instruct",
      description: [
        "Translate a document",
        "Write an email",
        "Write a document",
      ],
      bgColor: "bg-gray-50 border border-[#E7E7E7]",
    },
    {
      title: "Generate Code",
      link: "/modelStudio/Code Generation/CodeQwen1.5-7B-Chat",
      description: ["Create a basic web application", "Debug code"],
      bgColor: "bg-gray-50 border border-[#E7E7E7]",
    },
    {
      title: "Generate 3D Shapes",
      link: "/modelStudio/Text to 3D/Shap-E",
      description: [
        "Experiment with 3D",
        "Create virtual environments",
        "Create virtual characters",
      ],
      bgColor: "bg-gray-50 border border-[#E7E7E7]",
    },
    {
      title: "Image Analysis",
      link: "/modelStudio/Image-Text to Text/Paligemma-3b-pt-896",
      description: [
        "Describe images",
        "Generate captions",
        "Q&A about images",
      ],
      bgColor: "bg-gray-50 border border-[#E7E7E7]",
    },
    {
      title: "Transcribe Speech",
      link: "/modelStudio/Speech to Text/Whisper-small",
      description: [
        "Create transcripts of meetings or lectures",
        "Generate subtitles for videos",
      ],
      bgColor: "bg-gray-50 border border-[#E7E7E7]",
    },
  ];

  const chunkArray = (arr, size) =>
    arr.reduce(
      (chunks, _, i) =>
        i % size === 0 ? [...chunks, arr.slice(i, i + size)] : chunks,
      []
    );
  const sectionGroups = chunkArray(sections, 4);

  return (
    <div className="bg-white shadow-md rounded-lg w-full max-w-screen-lg mx-auto justify-between cursor-pointer mt-9">
      <div className="px-5 pt-5">
        <h2 className="font-bold text-2xl text-gray-900 mb-2">
          I want to Generate something using AI
        </h2>
        {/* <p className="text-gray-600 font-normal text-sm">
          Generate, demo, and fine-tune open-source AI models to build your AI
          application
        </p> */}
      </div>

      <Carousel
        animation="slide"
        navButtonsAlwaysVisible
        indicators={false}
        autoPlay={false}
        className="mt-3"
        navButtonsProps={{
          style: {
            backgroundColor: "#F2F2F2",
            color: "#282828",
          },
        }}
      >
        {sectionGroups.map((group, index) => (
          <Paper
            key={index}
            sx={{
              background: "none",
              boxShadow: "none",
              padding: "20px",
              // minHeight: "250px",
            }}
          >
            <div
              key={index}
              className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6"
            >
              {group.map((section, idx) => (
                <div
                  key={idx}
                  className={`rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 p-6 relative ${section.bgColor}`}
                  onClick={() => (window.location.href = section.link)}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) =>
                    e.key === "Enter" && (window.location.href = section.link)
                  }
                >
                  <h3 className="text-lg font-medium text-gray-800 mb-3">
                    <a href={section.link} className="hover:underline">
                      {section.title}
                    </a>
                  </h3>
                  <ul className="list-disc pl-5 text-gray-700 text-sm space-y-1">
                    {section.description.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </Paper>
        ))}
      </Carousel>
    </div>
  );
};

export default IwantSection;
