import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./modelsStyle.css";
import axios from "axios";
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism"; // Import from esm
import { CopyToClipboard } from "react-copy-to-clipboard";
import remarkGfm from "remark-gfm";
import { toast } from "react-toastify";

import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { PiCopySimpleThin } from "react-icons/pi";
import { IoCloseSharp } from "react-icons/io5";

// import TrialBanner from "../TrialBanner/TrialBanner";

import uploadImg from "../../assets/aiModels/cloud-upload-regular-240.png";
import fileDefault from "../../assets/aiModels/file-blank-solid-240.png";
import fileCSS from "../../assets/aiModels/file-css-solid-240.png";
import filePdf from "../../assets/aiModels/file-pdf-solid-240.png";
import filePng from "../../assets/aiModels/file-png-solid-240.png";
import Loader from "../../assets/aiModels/QLoader.svg";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";


const MAX_FILE_SIZE = 20 * 1024 * 1024 + 999; //21409408;
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const GetRagUi = () => {
  // const location = useLocation();
  const location = useLocation();
  const { model, modelIp } = location.state || {};

  console.log("Trigerred GetRagUi");
  const apiUrl = process.env.REACT_APP_RAG_UI_URL;
  console.log(`URL :${apiUrl}`);
  const wrapperRef = useRef(null);
  const messagesEndRef = useRef(null);

  const [fileList, setFileList] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [uploaded, setuploaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [filesUploaded, setFilesUploaded] = useState(false);
  const [buttonText, setButtonText] = useState("Upload");
  const [uploading, setUploading] = useState(false);
  const [copiedStates, setCopiedStates] = useState({});
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [chatActive, setChatActive] = useState(true);
  const [typing, setTyping] = useState(false);

  // const queryString = location.search; //commenting to make it stand alone
  // const urlParams = new URLSearchParams(queryString);
  // const action = urlParams.get("action");
  // const modelIP = urlParams.get("modelip");
  // Parse model if it was passed as a JSON string
  // const parsedModelIP = modelIP
  //   ? JSON.parse(decodeURIComponent(modelIP))
  //   : null;
  //8888888888888888888888888888888
  // console.log("Model:", parsedModelIP);
  const headerTitle = "RAG Ui";

  const ImageConfig = {
    default: fileDefault,
    pdf: filePdf,
    png: filePng,
    css: fileCSS,
  };

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleCopy = (messageId, codeIndex) => {
    const key = `${messageId}-${codeIndex}`;
    setCopiedStates((prevState) => ({
      ...prevState,
      [key]: true,
    }));

    setTimeout(() => {
      setCopiedStates((prevState) => ({
        ...prevState,
        [key]: false,
      }));
    }, 2000);
  };

  const handleSendMessage = (e) => {
    if (e) e.preventDefault();
    setChatActive(true);
    if (newMessage.trim() === "") return; // Don't send empty messages
    const messageToSend = newMessage;
    setMessages((prevMessages) => [
      ...prevMessages,
      { id: Date.now(), content: messageToSend, own: true },
    ]);
    setNewMessage("");
    sendMessage(messageToSend);
  };
  // Scroll to the bottom of the message list
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onFileChange = (files) => {
    //TODO: Remove these function
    console.log(files);
  };
  const onFileDrop = (e) => {
    e.preventDefault();
    // const newFile = e.target.files[0];
    const newFiles = Array.from(e.target.files);
    //Validation logic.
    if (newFiles.length) {
      let duplicateFound = false;
      let fileTooLarge = false;

      const nonDuplicateFiles = newFiles.filter((newFile) => {
        if (newFile.size > MAX_FILE_SIZE) {
          fileTooLarge = true; // Mark if file exceeds size limit
          return false; // Skip this file
        }

        const isDuplicate = fileList.some(
          (existingFile) =>
            existingFile.name === newFile.name &&
            existingFile.size === newFile.size &&
            existingFile.type === newFile.type
        );
        if (isDuplicate) {
          duplicateFound = true;
        }
        return !isDuplicate;
      });

      if (duplicateFound) {
        toast.error("File(s) already uploaded.");
      }

      if (fileTooLarge) {
        toast.error("File(s) too big! 20MB max.");
      }

      if (nonDuplicateFiles.length) {
        const updatedList = [...fileList, ...nonDuplicateFiles];
        setFileList(updatedList);
        onFileChange(updatedList);
      }
    }
    e.target.value = null;
  };
  const handleTyping = (e) => {
    if (
      (fileList.length === 0 && !uploaded) ||
      (fileList.length > 0 && !uploaded)
    ) {
      setShowWarningModal(true);
    } else if (fileList.length > 0 && uploaded) {
      setNewMessage(e.target.value);
    } else {
      setNewMessage(e.target.value);
    }
  };
  const fileRemove = (file) => {
    const updatedList = [...fileList];
    const removedFile = updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    onFileChange(updatedList);
    if (filesUploaded) {
      setButtonText("Update");
      setProgress(0);
    }
    toast.success(`File removed: ${removedFile[0].name}`);
  };

  const uploadFiles = async (event) => {
    // setLoading(true);
    setProgress(0);
    console.log("File list before upload:", fileList);

    const formData = new FormData();
    formData.append("operation", "upload");
    for (let i = 0; i < fileList.length; i++) {
      formData.append("files", fileList[i]); // Add each file to FormData
    }
    try {
      setUploading(true);
      // const response = await axios.post(`${parsedModelIP}/api`, formData, {
      const response = await axios.post(`${modelIp}/api`,
        formData,
        {
          // const response = await axios.post(
          //   "http://172.29.195.25:4175/api",
          //   formData,
          // {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        }
      );

      if (response.status === 200) {
        console.log("File uploaded successfully:", response.data);
        setFilesUploaded(true);
        toast.success(`${buttonText} successful!`);
        setuploaded(true);
      } else {
        console.error("File upload failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error during file upload:", error);
      toast.error("Error during file upload");
    } finally {
      setUploading(false);
    }

    // setLoading(false); // Hide loader after the upload is complete
  };

  const sendMessage = async (data) => {
    console.log("send message hit");
    const formData = new FormData();
    formData.append("operation", "query"); // Adding the operation field
    formData.append("question", data);
    try {
      setTyping(true);
      // const response = await fetch(`${parsedModelIP}/api`, {
      //   method: "POST",
      //   body: formData,
      // });
      // const response = await axios.post(`${parsedModelIP}/api`, formData);
      const response = await axios.post(`${modelIp}/api`,
        formData
      );

      if (response.status === 200) {
        // const result = await response.json();
        // console.log("REsponse", result);
        setMessages((prevMessages) => [
          ...prevMessages,
          { id: Date.now(), content: response?.data?.answer, own: false },
        ]);
      } else {
        console.error(" failed:", response.statusText);
        toast.error("Failed fetching response");
      }
    } catch (error) {
      console.error("Error getting response:", error);
      toast.error("No response from server");
    } finally {
      setTyping(false);
    }
  };

  const formatSize = (size) => {
    if (size < 1024) {
      return `${size}B`; // Size in Bytes
    } else if (size < 1024 * 1024) {
      return `${Math.ceil(size / 1024)}KB`; // Size in KB
    } else {
      return `${Math.ceil(size / (1024 * 1024))}MB`; // Size in MB
    }
  };
  const formatName = (fname) => {
    // return fname.length > 12 ? `${fname.substring(0,13)}... .${fname.split('.')[1]}` : fname;
    return fname.length > 12 ? `${fname.substring(0, 16)}...` : fname;
  };
  // Automatically scroll to the bottom when messages change
  useEffect(() => {
    scrollToBottom();
    if (messages.length === 0) {
      setChatActive(false);
    }
  }, [messages]);

  return (
    <div className="flex w-full min-h-screen">
      {/* <SideBar /> */}
      {/* <Header title={headerTitle} /> */}
      {/* <TrialBanner /> */}
      {/* <Footer /> */}
      {/* Sidebar Section */}
      <Sidebar className="flex-shrink-0 h-full fixed" />
      <div className="flex flex-col w-full">
        {/* Navbar Section */}
        <div className="ml-[90px]">
          <Navbar className="flex-shrink-0 fixed w-full" />
        </div>

        {/* Page content */}
        <div className="ml-[90px] px-5 lg:px-10 pt-24 InitialStyle h-full overflow-auto">
          {/* Page Heading & Description */}
          <div className=" pr-[20%] mb-5 text-justify">
            <p className="text-[16px] font-[500]">RAG</p>
            <p className="text-[#10001AE5] opacity-90 text-[14px] font-[500]">
              RAG is an ai framework that combines the strength of traditional
              information retrieval systems.
            </p>
          </div>
          {/* Main Content */}
          <div className="chat__box pb-2 flex flex-col sm:flex-row justify-center gap-y-5 sm:gap-x-5 mb-[30px] sm:min-h-[64vh] md:min-h-[70vh]">
            {/* <div className=" w-[370px] flex flex-col justify-between"> */}
            <div className=" w-full sm:w-[370px] p-4 border shadow-md rounded-[8px] flex flex-col justify-between overflow-auto bg-white">
              {/* file drop zone */}
              <div
                ref={wrapperRef}
                className="drop-file-input bg-white"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
              >
                <div className="drop-file-input__label">
                  <img src={uploadImg} alt="" />
                  {/* <IoCloudUpload style={{ width: '80px', color: 'red' }} /> */}
                  <p>Click or Drop your files here</p>
                </div>
                <input type="file" multiple onChange={onFileDrop} />
              </div>

              {fileList.length > 0 ? (
                <div className="drop-file-preview">
                  {uploading ? (
                    <button className="loader-button bg-white w-[130px] h-[42px] mb-2.5">
                      <svg
                        className="animate-spin h-5 w-5 mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.96 7.96 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zm5.195 2.647A8.034 8.034 0 0112 20c4.418 0 8-3.582 8-8h-4c0 2.761-2.239 5-5 5a4.998 4.998 0 01-4.805-3.653z"
                        ></path>
                      </svg>
                      {"wait... "}
                      {progress}%
                    </button>
                  ) : (
                    <button
                      className="classic-button bg-white w-[130px] h-[42px] mb-2.5"
                      onClick={uploadFiles}
                    >
                      {buttonText}
                    </button>
                  )}
                  {fileList.map((item, index) => (
                    <>
                      <div className="drop-file-preview__item0">
                        <div key={index} className="drop-file-preview__item">
                          <img
                            src={
                              ImageConfig[item.type.split("/")[1]] ||
                              ImageConfig["default"]
                            }
                            alt=""
                          />
                          <div className="drop-file-preview__item__info">
                            <p>{formatName(item.name)}</p>
                            <p>{formatSize(item.size)}</p>
                            {/* <p>{progress}</p> */}
                          </div>

                          <span
                            className="drop-file-preview__item__del"
                            onClick={() => fileRemove(item)}
                          >
                            x
                          </span>
                        </div>
                        <LinearProgressWithLabel value={progress} />
                      </div>
                    </>
                  ))}
                </div>
              ) : null}
            </div>

            <div className="sm:w-[910px] p-4 border shadow-md rounded-[8px] relative grow flex flex-col overflow-auto w-full bg-white">
              {/* Message container */}
              <div className="flex-1 overflow-y-auto mb-4">
                {/* Message part */}
                {chatActive ? (
                  <div className="flex flex-col space-y-2">
                    {messages.map((message) =>
                      message.own ? (
                        // Div for when message.own is true
                        <div
                          key={message.id}
                          className="own bg-blue-100 p-2 rounded-md"
                        >
                          <span>{message.content}</span>
                        </div>
                      ) : (
                        // Div for when message.own is false
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: "12px",
                          }}
                        >
                          <div className="flex justify-center">
                            <img
                              src={Loader}
                              className="h-10 w-10  text-gen-color"
                              alt=""
                            />
                          </div>
                          {/**Mark down rendering char response. */}
                          <div>
                            <Markdown
                              className="resize-y w-full"
                              children={message.content}
                              remarkPlugins={[remarkGfm]}
                              components={{
                                code({
                                  node,
                                  inline,
                                  className,
                                  children,
                                  ...props
                                }) {
                                  const match = /language-(\w+)/.exec(
                                    className || ""
                                  );
                                  const codeIndex =
                                    node?.position?.start?.offset;
                                  return match ? (
                                    <div style={{ position: "relative" }}>
                                      <SyntaxHighlighter
                                        style={solarizedlight}
                                        language={match[1]} //here these gives me js
                                        PreTag="div"
                                        {...props}
                                      >
                                        {/* {String(children).replace(/^language-\w+\s+/, '')} */}
                                        {String(children).replace(/\n$/, "")}
                                      </SyntaxHighlighter>
                                      <CopyToClipboard
                                        // text={String(children).replace(/^language-\w+\s+/, '')}
                                        text={String(children).replace(
                                          /\n$/,
                                          ""
                                        )}
                                        onCopy={() =>
                                          handleCopy(message.id, codeIndex)
                                        }
                                      >
                                        <button className="copy-to-clipboard__button">
                                          {copiedStates[
                                            `${message.id}-${codeIndex}`
                                          ] ? (
                                            "Copied!"
                                          ) : (
                                            <>
                                              <PiCopySimpleThin /> {"Copy code"}
                                            </>
                                          )}
                                        </button>
                                      </CopyToClipboard>
                                    </div>
                                  ) : (
                                    // <code className={`${className} px-2 py-1 rounded bg-gray-200`} {...props} >
                                    <code className={`${className}`} {...props}>
                                      <span className="rounded bg-gray-200">
                                        {children}
                                      </span>
                                    </code>
                                  );
                                },
                              }}
                            />
                          </div>
                        </div>
                        // </div>
                      )
                    )}
                    {typing && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: "12px",
                        }}
                      >
                        <div className="flex justify-center">
                          <img
                            src={Loader}
                            className="h-10 w-10 animate-spin text-gen-color duration-[10000ms]"
                            alt=""
                          />
                        </div>
                        <div>
                          <div className="message bg-blue-100 p-2 rounded-md w-max flex items-center space-x-1">
                            <div className="flex items-center space-x-1">
                              <div className="">Typing</div>
                              <div className="animate-bounce [animation-delay:-0.3s]">
                                .
                              </div>
                              <div className="animate-bounce [animation-delay:-0.15s]">
                                .
                              </div>
                              <div className="animate-bounce">.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* This div serves as a target to scroll to bottom*/}
                    <div ref={messagesEndRef} />
                  </div>
                ) : (
                  <>
                    <div className="flex justify-center items-center h-full text-center">
                      <span className="font-medium text-[18px]">
                        What can I help you?
                      </span>
                    </div>{" "}
                  </>
                )}
              </div>

              {/* Send button part to be fixed at the bottom */}
              <div className="sticky bottom-0 p-2">
                <hr />
                <form onSubmit={handleSendMessage}>
                  <label htmlFor="chat" className="sr-only">
                    Your message
                  </label>
                  <div className="flex items-center px-3 py-2 rounded-lg bg-white dark:bg-gray-700">
                    <textarea
                      id="chat"
                      rows="1"
                      className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Your message..."
                      value={newMessage}
                      onChange={handleTyping}
                      onKeyDown={handleKeyPress}
                    ></textarea>
                    <button
                      type="submit"
                      className="inline-flex justify-center p-2 text-gen-color rounded-full cursor-pointer hover:bg-blue-100 dark:text-purple-500 dark:hover:bg-gray-600"
                    >
                      <svg
                        className="w-5 h-5 rotate-90"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 18 20"
                      >
                        <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z" />
                      </svg>
                      <span className="sr-only">Send message</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* Modal  */}
          {showWarningModal && (
            <div className="fixed z-[9999] inset-0 overflow-y-auto">
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                {/* Backdrop */}
                <div
                  className="fixed inset-0 transition-opacity"
                  aria-hidden="true"
                >
                  <div
                    className="absolute inset-0 bg-gray-500 opacity-75"
                    onClick={() => setShowWarningModal(false)}
                  ></div>
                </div>
                {/* Main Component */}
                <div className="m-auto min-w-[30vw] bg-white rounded-xl pt-5 pb-4 shadow-xl transform transition-all">
                  <div>
                    <div className="w-full px-5 pb-5 flex justify-end">
                      <IoCloseSharp
                        className="text-2xl cursor-pointer"
                        onClick={() => setShowWarningModal(false)}
                      />
                    </div>
                    <hr className="w-full" />
                    <div className="my-10 text-center sm:mt-5 px-7">
                      <span className="font-medium text-[18px]">
                        You can not ask question without uploading file. <br />
                        Please upload the file.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Footer Section */}
        <div className="ml-[90px]">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default GetRagUi;
