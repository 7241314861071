import React, { useState, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { MdClose } from "react-icons/md";
import { useData } from "./ModelContext";
// Import the custom hook

const DataPreviewModal = ({
  goBack,
  onClose,
  isOpen,
  setIsOpen,
  inputColumns,
  outputColumns,
  fileUrl
}) => {
  // const { setData } = useData(); // Access the setData function
  const { setInputColumns, setOutputColumns, setfileUrl } = useData();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleDataPreviewModalClose = () => {
    // Check if inputColumns and outputColumns are valid
    if (inputColumns.length !== 0 && outputColumns.length !== 0) {
      setInputColumns(inputColumns); // Clear or reset input columns
      setOutputColumns(outputColumns);
      setfileUrl(fileUrl);
    } else {
      console.error("Input or Output Columns are empty");
    }
    
    onClose();
    setIsOpen(false);
  };
  

  const maxRows = Math.max(inputColumns.length, outputColumns.length);

  return (
    <div>
      {isOpen && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mb-10">
          {/* Dark Overlay */}
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
          {/* Modal Content */}
          <div className="relative mx-auto my-auto max-w-3xl w-full z-50">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/* Modal Header */}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <div>
                  <h4 className="text-lg font-semibold">
                    <span className="flex items-center">
                      <FaArrowLeft
                        className="mr-2 cursor-pointer"
                        onClick={goBack}
                      />{" "}
                      Data Preview
                    </span>
                  </h4>
                  <p className="text-sm text-[#10001ACC] ml-7">
                    Showing 1 of {maxRows}
                  </p>
                </div>

                <button
                  className="background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                  type="button"
                  onClick={onClose}
                >
                  <MdClose className="w-5 h-5 mt-2" />
                </button>
              </div>

              {/* Paragraph */}
              <p className="p-4">
                The model only gets one input and output, so if there are
                multiple inputs or multiple outputs, they will be merged for
                tuning.
              </p>

              {/* Table */}
              <div className="overflow-x-auto p-4">
                {loading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>{error}</p>
                ) : (
                  <table className="min-w-full border rounded-md pb-4">
                    <thead className="bg-[#EFF0F8CC]">
                      <tr className="text-center text-sm text-[#10001A]">
                        <th className="py-2 px-4">Input</th>
                        <th className="py-2 px-4">Output</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[...Array(maxRows)].map((_, index) => (
                        <tr
                          key={index}
                          className={`bg-white hover:bg-gray-100 text-[#10001A] ${
                            index % 2 === 1 ? "bg-[#F8F9FD80]" : ""
                          }`}
                        >
                          <td className="py-2 px-4 text-center text-gray-700">
                            {inputColumns[index] || "-"}
                          </td>
                          <td className="py-2 px-4 text-center text-gray-700">
                            {outputColumns[index] || "-"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>

              {/* Modal Footer */}
              <div className="flex justify-between items-center p-4 border-t border-solid border-slate-200 rounded-b">
                <p>
                  Avoid importing sensitive or personal information. Only text
                  is imported.
                </p>
                <button
                  className="classic-button h-[40px] w-[140px] text-[18px] font-[600]"
                  onClick={
                    () => handleDataPreviewModalClose(inputColumns, outputColumns)
                  }
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DataPreviewModal;
